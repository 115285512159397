/* playlistsMain.scss */

.content-body-playlist.light {
    background-color: #ffffff;
    color: #000;
  }
  
  .content-body-playlist.dark {
    background-color: #121212;
    color: #fff;
  }
  
  .deshboard-content-playlist.light {
    background-color: #ffffff;
  }

  
  .title-search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
padding-top: -50;
  }
  
  .title-search-container h2 {
    color: #BFBFBF;
    font-size: 24px;
  }
  
  .search-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 324px;
  }
  
  .search-box {
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border-radius: 6px;
    padding-right: 40px; /* Space for the button inside */
  }
  
  .search-button {
    position: absolute;
    right: 10px; /* Position button inside the input field */
    top: 50%;
    transform: translateY(-50%);
    padding: 10px;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
  
  .search-icon {
    color: #D22F26;
  }
  /* SCSS (playlistsMain.scss) */

.content-body-playlist {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  .deshboard-content-playlist {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .title-search-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .search-container {
    display: flex;
    align-items: center;
  }
  
  .search-box {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .search-button {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  .fixed-width-boxes {
    display: flex;
    gap: 16px;

    width: 100%;
  }
  
  .folders {
    width: 60%; 
    padding: 20px;
    background-color: #121212;
    border-radius: 6px;
    border: 1px solid #FFFFFF29;
    height:760px;
  }
  
  .sab-folder-list {
    width: 40%; /* Fixed width for the second box */
    padding: 20px;
    background-color: #121212; /* Example background color */
    border-radius: 6px;
    border: 1px solid #FFFFFF29
  }
 