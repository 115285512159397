.dashboard-content .top-content .left-side h3 {
    color: #333;
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}
.master-setting-table {
    background-color: #fff;
    padding: 25px 20px;
    border-radius: 6px;
    margin-top: 15px;
}
.theme-image {
    width: 40px;
    height:40px;
}
.setting-genre {
    span {
        border-radius: 6px;
        border: 0.5px solid #E6E6E6;
        background: #FFF;
        box-shadow: 0px 4px 16px 0px rgba(153, 153, 153, 0.08);
        display: inline-flex;
        padding: 8px 16px;
        margin-right: 16px;
        color: #999;
        position: relative;
        overflow: visible;
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }
        &.active {
            border-color: #D22F26;
            color:#333;
            &:after {
                content: " ";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 12px 12px 12px;
                border-color: transparent transparent #ffffff transparent;
                position: absolute;
                top: 42px;
                left: calc(50% - 12px);
            }
        }
    }
}
.master-setting-list {
    width: 100%;
    tr {
        border-bottom: 1px solid #EFE2E2;
    }
    th {
        text-transform: uppercase;
        background: #EFE2E2;
        padding: 14px 15px;
        font-size: 14px;
        color: #927F7F;
        font-weight: 500;
        line-height: 20px;
    }
    th, td {
        font-weight: 500;
        line-height: 20px;
        padding: 14px 15px;
        color: #333;
        &:first-child {
            width: 10%;
        }
        &:nth-child(2) {
            width: 20%;
        }
        &:nth-child(3) {
            width: 20%;
        }
        &:nth-child(4) {
            width: 23%;
        }
        &:nth-child(5) {
            width: 14%;
        }
        &:nth-child(7) {
            width: 15%;
        }
        .form-col {
            width: 95%;
            // padding-left: 50px;
            // padding-right: 50px;
            // padding-bottom: 20px;
        
            &.full-form-col {
              width: 100%;
            }
        }
        .form-field {
            width: 100%;
            display: flex;
            flex-direction: column;
        
            input:not([type="radio"]), input:not([type="checkbox"]),
            select,
            & > textarea,
            .react-datepicker__input-container input {
            //   height: 48px;
            //   width: 100%;
            //   @include border-radius(6px);
              border: 1px solid #e6e6e6;
              padding: 8px 16px 6px 7px;
              color: #8e9192;
              font-size: 16px;
              outline: none;
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              background-color: #ffffff;
        
              &:focus,
              &:active {
                color: var(--bs-body-color);
                background-color: var(--bs-body-bg);
                border-color: #86b7fe;
                outline: 0;
                box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
              }
            }
            select {
            //   background-image: url(../images/dropdown-arrow.svg)!important;
              background-image: url(../../assets/images/dropdown-arrow.svg)!important;
              background-repeat: no-repeat!important;
              background-position: calc(100% - 3px) center!important;
            }
        }
    }
    .table-icon {
        width: 33%;
        display: inline-block;
        cursor: pointer;
    }
    .img-icon {
        border-radius: 12px;
        background: #EFEBEB;
        padding: 4px 8px;
        margin-right: 12px;
        &:last-of-type {
            margin-right: 0;
        }
    }
}
.dark-theme {
    .setting-genre span {
        background: #262728;
        border-color: #19191a;
        color: #c3c3c3;
        &.active {
            border-color: #D22F26;
            &:after {
                border-color: transparent transparent #262728 transparent
            }
        }
    }
    .master-setting-table {
        background: #262728;
        thead tr {
            border-color: #1c1c1c;
        }
        th {
            background: #262728;
            color: #c3c3c3;
        }
    }
    .master-setting-list .img-icon {
        background-color: #1c1c1c;
    }
}
@media (max-width: 1300px) {
    .master-setting-list {
        th, td {
            &:first-child {
                width: 10%;
            }
            &:nth-child(2) {
                width: 15%;
            }
            &:nth-child(3) {
                width: 30%;
            }
            &:nth-child(4) {
                width: 27%;
            }
            &:nth-child(5) {
                width: 18%;
            }
        }
    }
}
@media (max-width: 1100px) {
    .master-setting-list {
        th, td {
            padding: 14px 5px;
            &:first-child {
                width: 10%;
            }
            &:nth-child(2) {
                width: 15%;
            }
            &:nth-child(3) {
                width: 28%;
            }
            &:nth-child(4) {
                width: 25%;
            }
            &:nth-child(5) {
                width: 22%;
            }
        }
    }
}
@media (max-width: 950px) {
    .master-setting-list .table-icon {
        width: unset;
    }
}