.multiselect-field {
    .searchWrapper {
        border:1px solid #E6E6E6;
        background: #ffffff;
        // @include border-radius(6px);
        display: flex;
        align-items: center;
        min-height: 48px;
        padding: 7px 13px 2px 13px;
        flex-wrap: wrap;

        .chip {
            background: rgba(210, 47, 38, 0.14);
            color: #2E3132;
            // @include border-radius(5px);
            height: 31px;
            display: flex;
            align-items: center;
            font-size: 16px;
            margin-left: 5px;

            i {
                margin-left: 10px;
                cursor: pointer;
            }
        }

        input {
            margin: 0px;
            padding: 0px;
            height: 31px;
            margin-bottom: 5px;
            border: none;
            outline: none;
            font-size: 16px;
            color: #8E9192;
        }

        .icon_down_dir {
            right: 15px;

            &::before {
                display: none;
            }
        }
    }

    .optionListContainer {
        li {
            &.highlightOption {
                background: #D22F26;
            }

            &:hover {
                background: #D22F26;
            }
        }
    }
}