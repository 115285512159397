.file-explorer {
    font-family: Arial, sans-serif;
  }
  
 
  
  

  
  .back-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  
  .file-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
  }
  
  .file-item {
    border: 1px solid #ddd;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .file-item:hover {
    background-color: #f5f5f5;
  }
  
  .folder {
    display: flex;
    align-items: center;
  }
  
  .icon {
    margin-right: 5px;
  }
  
  .file {
    display: flex;
    align-items: center;
  }
  
  .file img,
  .file video {
    max-width: 100%;
    max-height: 100%;
  }
  
  .file span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .image-file{
    width:30px;
    height: 30px;
  }