
.bannerpopup {
  .modal {
    width: 100% !important;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    .modal-dialog {
      width: 100%;
      min-height: 371px;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .modal-content {
        width: 50%;
        height: 80%;
        background-color: #fff;
        border-radius: 10px;
        .modal-body {
          overflow: auto;
          
        }
        .upload-button {
          background: #d22f26;
          color: #ffffff;
          padding: 10px 10px;
          font-size: 14px;
          font-weight: 600;
          border-radius: 5px;
        }
        .image-container {
          position: absolute;
          right: 20px;
          width: 150px;
          height: 150px;
          border-radius: 5px;
          background: lightgray;
          background-image: url("../../../assets/images/placeholder-image.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 50% 50%;
        }
      }
    }
  }
}
.startDatepicker,
.endDatepicker {
  padding: 12px 16px !important;
  border-radius: 6px !important;
  border: 1px solid #e6e6e6 !important;
  padding: 15px 16px !important;
  margin: 0 !important;
  min-width: 246px;
}
.Lables{
  color:#c3c3c3;

}
.TypeBanner{
  color: #e6e6e6;
  font-weight: 400;
}
.bannerDates{
  display: flex;


}
