.channel-creation-form {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.width-80{
    width: 80% !important;
}
.width-67 {
    width: 66.67% !important;
}
.width-33{
    width: 33.3% !important;
}
.width-30{
    width: 30% !important;
}
.width-20{
    width: 20% !important;
}
.width-10{
    width: 10% !important;
}
.btn-delete {
    filter: brightness(0) invert(1);
    padding-left: 0;
}
#json-data-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#json-data-scroll::-webkit-scrollbar
{
	width: 8px;
    height: 8px;
	background-color: #F5F5F5;
}

#json-data-scroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #a7a7a7;
    cursor: pointer; 
}
.jsoneditor-react-container .custom-json-editor{
    font-size: 166px !important; /* Change this value to your desired size */
  }