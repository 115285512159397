// .image-gallery-image{
//     display: none ;
// }





.fullscreen .image-gallery-image{
    display: block ;}


.image-gallery-fullscreen-button{
    // top:5px ;
    padding:5px ;
}
.fullscreen .image-gallery-fullscreen-button{
    top: 96%;
    right: 20px;
    padding: 5px;
}
.close-fullscreen-button {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000; /* Make sure it's higher than the z-index of the fullscreen gallery */
    background-color: white; /* Button background color */
    border: none;
    padding: 10px; /* Adjust padding as needed */
    cursor: pointer;
  }
  .image-viewer {
  
    
    .image-gallery {
      .image-gallery-thumbnails {
        
    // position: absolute;
    /* height: 553px; */
    // right: 0;
    // bottom: 0;
    // width: 100px;
    width: 100%;
   
    // padding: 10px;
      }
  
     
  
      .image-gallery-thumbnails-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
      .image-gallery-thumbnails-container{
        background: #00000061;
      }


    }
  }
  
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(90vh - 80px);
}



.imageViewer-modal.show .modal-dialog {
  position: absolute;
}

.imageViewer-modal{
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
 
// border: none;
 
background-color: transparent;
}



.custom-text-fullscreen{
  min-width: 350px;
  color: white;
  position: absolute;
  height: 120px;
  bottom: 15px;
  left: 2%;
  z-index:  1;
  padding: 10px 15px;
// border: 1px solid ;
// border-radius: 8px;

// .small-font{
// line-height: 8px;
// font-size: 14px;

//   }
// }
// .title-font{
// color: white;
// font-size: 20px;
// width: 400px;
// height: auto;

//   }
//   .modal-backdrop {
  
    --bs-backdrop-opacity: 0.7;}
    [role=button] {
      cursor:default;
  }
}
