.expand-content{
    gap:20px 0px
}
.scroll-height{
    overflow-y: auto;
    height: 600px;
}

.scroll-height::-webkit-scrollbar {
    width: 3px; /* Set the width of the scrollbar */
  }
  
  .scroll-height::-webkit-scrollbar-track {
    background: #f1f1f1; /* Set the background color of the track */
  }
  
  /* Change the appearance of the scrollbar thumb (the draggable part) */
  .scroll-height::-webkit-scrollbar-thumb {
    background: #000000; /* Set the background color of the thumb */
    border-radius: 6px; /* Add rounded corners to the thumb */
  }
  
  /* Change the appearance when the scrollbar is hovered over */
  .scroll-height::-webkit-scrollbar-thumb:hover {
    background: #E0E0E0;
  }




.right-bar{
    height: 100%;
    display: block;
    width: 196px;
margin: auto;
    padding: 20px;
border-radius: 8px;
border: 1px solid #E6E6E6;
background: #FFF;
box-shadow: 0px 8px 24px 0px rgba(16, 24, 40, 0.08);
.right-head{
    color: #333;
font-family: Roboto;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 100%; /* 20px */
}
}

.detail-inputs {
  width: 100%;
    background: white !important;
    display: flex;
padding: 12px 16px;
align-items: center;
gap: 8px;
align-self: stretch;
border-radius: 6px;
border: 1px solid #E6E6E6;

color: #222;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
letter-spacing: 0.2px;

&:focus, &:active {
  color: var(--bs-body-color);
background-color: var(--bs-body-bg);
border-color: #86b7fe;
outline: 0;
box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);

}
}


.breadcrumbs a{
    color: #999;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    color: #999999;
}
.selected-block{
  // border: 1px solid #D22F26 !important;
  // box-shadow: 0px 16px 32px rgb(117 94 135 / 30%);
 
  background: linear-gradient(#0D0D0D, #0D0D0D) padding-box  , 
  linear-gradient(to right, #22BBBE, #7DAD41, #F4B719, #E55824, #B52C80) border-box !important;
  border-radius: 6px !important;
  border: 1px solid transparent !important;
  box-shadow: 0px 16px 32px 0px rgba(16, 24, 40, 0.16) !important;
}

