@import "../../assets/css/variables.scss";
/* FolderPopup.css */

.modal-dialog {
  width: 555px;
  height: 371px;
  /* Adjust the maximum width as needed */
}

.partnermodal {
  opacity: 1;
  :global .modal-dialog {
    width: 555px;
    height: 371px;
  }
}
.partnerDatepicker {
  padding: 12px 16px !important;
  border-radius: 6px !important;
  border: 1px solid #e6e6e6 !important;
  padding: 12px 16px !important;
  margin: 0 !important;
  min-width: 246px;
}
.startTimePicker {
  width: 48% !important;
}

.modal-content {
  border-radius: 0;
}

.modal-header {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;

  text-align: left;
  border: none;
}

.modal-title {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

.modal-body {
  padding: 0px 20px;

  label {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0px;
    text-align: left;
  }

  .modal-content {
    border-radius: 0;
  }

  .modal-header {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;

    text-align: left;
    border: none;
  }

  .modal-title {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
  }

  .modal-body {
    padding: 0px 20px;

    label {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 700;
      margin: 10px 0px;
      text-align: left;
    }

    input {
      padding: 12px 16px;
      border-radius: 6px;
    }

    input::placeholder {
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;

      text-align: left;
      color: #8e9192;
    }

    .react-datepicker-wrapper input {
      border: 1px solid #dee2e6;

      margin: 0px 20px;
      text-align: center;
      padding: 0.375rem 0.75rem;
      width: 100%;

      &:focus {
        color: var(--bs-body-color);
        background-color: var(--bs-body-bg);
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
      }
    }
  }

  .modal {
    .Add {
      width: 77px;

      background: #d22f26;

      padding: 14px, 24px, 14px, 24px;
      border-radius: 8px;
      border: none;
    }

    .Cancel {
      margin-right: 30px;

      padding: 14px, 24px, 14px, 24px;
      border-radius: 8px;
      background: #faeae9;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 500;
      border: none;
      text-align: left;
      color: #d22f26;
    }

    .modal-footer {
      margin-top: 15px;
      justify-content: left;
      border: none;
    }

    .btn-close {
      font-size: 12px;
    }
  }
}

/* Style for the time select input */
.react-datepicker-time__input-container {
  display: inline-block;
  width: 80px;
  /* Adjust the width as needed */
  margin-right: 10px;
  /* Add spacing between the date and time inputs */
}

/* Style for the time input */
.react-datepicker-time__input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  /* Center the text */
}

/* Style for the time select arrow (down arrow) */
.react-datepicker-time__input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  fill: #000;
  /* Color of the arrow */
}

/* Style for the time select dropdown container */
.react-datepicker__time-container {
  display: flex;
  justify-content: center;
}

/* Style for the time select dropdown */
.react-datepicker__time {
  max-width: 100px;
  /* Adjust the width as needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  font-size: 14px;
}

/* Style for the time select options */
.react-datepicker__time-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Style for individual time select option */
.react-datepicker__time-list-item {
  cursor: pointer;
  padding: 8px;
  text-align: center;
}

/* Style for the selected time option */
.react-datepicker__time-list-item--selected {
  background-color: #007bff;
  /* Background color of the selected option */
  color: #fff;
  /* Text color of the selected option */
}

/* Hover style for time select option */
.react-datepicker__time-list-item:hover {
  background-color: #f2f2f2;
}

.starttime {
  width: 45% !important;
}
