.dropdown-block select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: no-repeat right 10px center;
    padding: 10px 20px 10px 10px;
    font-size: 16px;
    width: 100%;
    cursor: pointer;
    color: aliceblue;
    border: none; /* Removed the border */
    background-color: transparent; /* Optional for a clean look */
  }
  
  .dropdown-block select:focus {
    outline: none;
  }
  
  .dropdown-block {
    position: relative;
    margin-top: 10px;
    background-color: white; 
  }
  
  /* Add arrow for browsers that don't support custom appearance */
  .dropdown-block select::-ms-expand {
    display: none;
  }
  .radio-box img {
    transition: filter 0.3s ease;
  }
  
  .radio-box .dropdown-arrow {
    filter: grayscale(100%);
  }
  
  .radio-box.active .dropdown-arrow {
    filter: none;
  }
  .dropdown-options  {
    position: absolute;
    // background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    padding: 10px;
    width: 200px;
    z-index: 100;
    display: block;
    color: white; 
    gap: 20;
     max-height: 200px; // Limit height
  overflow-y: auto; // Add vertical scroll
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .dropdown-options::-webkit-scrollbar {
    width: 6px; /* Custom width for the scrollbar */
  }
  .dropdown-options::-webkit-scrollbar-track {
    background: #1a1a1a; /* Background color of the track */
  }
  
  .dropdown-options::-webkit-scrollbar-thumb {
    background: #bb0101; /* Scrollbar color */
    border-radius: 10px; /* Rounded corners */
  }
  
  .dropdown-options::-webkit-scrollbar-thumb:hover {
    background: #bb0101; /* Hover color */
  }
  
  .dropdown-option {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-option:hover {
    background-color: '#444648';
  }
  .dropdown-option input[type="checkbox"]:checked + label {
    color: red; /* Change label color when checked */
  }
  .selected-items {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  
    div {
      background-color: white;
      color: black;
      padding: 10px 15px;
      margin: 5px;
      border-radius: 5px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border: 1px solid #ccc;
    }
  }
  .search-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  
  