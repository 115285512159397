@import "../../assets/css/variables.scss";
.dashboard-header {
  // background-color: #ffffff;
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 16px $border_box;
  position: relative;
  z-index: 1;
  .logo {
    display: inline-block;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    
  }
  .welcome-name {
    display: inline-block;
    vertical-align: middle;
    padding-left: 80px;
    h5 {
      margin: 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
    }
    .date {
      span {
        color: #646464;
        font-size: 14px;
        line-height: 22px;
      }
      img {
        margin-top: -3px;
        margin-right: 5px;
      }
    }
  }

  .dropdown.show {
    top: 64px;
    width: 150px;
  }

  .right-side {
    display: flex;
    flex-direction: row;
    .checkbox-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 50px;
      label {
        margin-left: 10px;
      }
    }
  }

  /* ========= theme check ==========*/

  .theme-check {
    display: flex;
    align-items: center;
    position: relative;

    input[type="checkbox"] {
      height: 0;
      width: 0;
      visibility: hidden;
      position: absolute;
    }

    label {
      cursor: pointer;
      text-indent: 50px;
      width: 42px;
      height: 24px;
      background: #ffffff;
      border: 1px solid #000000;
      display: block;
      border-radius: 12px;
      position: relative;
      line-height: 24px;
    }

    label:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      width: 14px;
      height: 14px;
      background: #d22f26;
      opacity: 0.5;
      border-radius: 90px;
      transition: 0.3s;
    }

    input:checked + label {
      background: #000000;
    }

    input:checked + label:after {
      left: calc(100% - 1px);
      transform: translateX(-100%);
      background: #ffffff url(/assets/images/dark_theme.svg) no-repeat;
      opacity: 1;
      width: 20px;
      height: 20px;
      top: 1px;
      background-position: center;
    }
    input + label:after {
      left: 0;
      transform: translateX(0%);
      background: #ffffff url(/assets/images/light_theme.svg) no-repeat;
      opacity: 1;
      width: 20px;
      height: 20px;
      top: 1px;
      background-position: center;
    }
    .label {
      color: #ffffff;
      font-weight: 400;
      font-size: 14px;
      margin-left: 8px;
    }
  }

  /* ============== theme check end ==============*/

  &.dark {
    background-color: $dark_navbar_background;
    .welcome-name {
      h5 {
        color: $dark_heading_text;
      }
      .date {
        span {
          color: $dark_subtext;
        }
      }
    }
    .theme-check {
      input:checked + label {
        background: $universal_white;
        border: 1px solid $universal_white;
      }
    }
    .user {
      .user-dropdown {
        a.box {
          &:after {
            filter: invert(100%);
          }
          &.active {
            &:after {
              filter: invert(0%);
            }
          }
        }
      }
    }
    // .logo {
    //   background-image: url("../../assets/images/light_logo.png");
    //   width: 135px;
    //   height: 25px;
    // }
  }


  &.light {
    background-color: $light_navbar_background;
    .welcome-name {
      h5 {
        color: $light_heading_text;
      }
      .date {
        span {
          color: $light_subtext;
        }
      }
    }
    .theme-check {
      input:checked + label {
        background: $universal_white;
        border: 1px solid $universal_black;
      }
    }
    .user {
      .user-dropdown {
        a.box {
          &::after {
            filter: invert(0%);
          }
          &.active {
            &:after {
              filter: invert(0%);
            }
          }
        }
      }
    }
    .logo {
      // background-image: url("../../assets/images/logo.png");
      // width: 135px;
      // height: 30px;
    }
  }

  &.monitor {
    &.dashboard-header .welcome-name h5 {
      color:#E0E0E0;
    }
    .logo {
      background-image: unset;
      img {
        max-width: 100%;
      }
    }
    .date {
        span {
            color: #E0E0E0;
            font-size: 14px;
            line-height: 22px;
        }
    }
    .clock {
        background-image: dark(url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJXaW5kb3dUZXh0IiBkPSJNMTEuOTkgMkM2LjQ3IDIgMiA2LjQ4IDIgMTJzNC40NyAxMCA5Ljk5IDEwQzE3LjUyIDIyIDIyIDE3LjUyIDIyIDEyUzE3LjUyIDIgMTEuOTkgMnpNMTIgMjBjLTQuNDIgMC04LTMuNTgtOC04czMuNTgtOCA4LTggOCAzLjU4IDggOC0zLjU4IDgtOCA4eiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBmaWxsPSJXaW5kb3dUZXh0IiBkPSJNMTIuNSA3SDExdjZsNS4yNSAzLjE1Ljc1LTEuMjMtNC41LTIuNjd6Ii8+PC9zdmc+), url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMTEuOTkgMkM2LjQ3IDIgMiA2LjQ4IDIgMTJzNC40NyAxMCA5Ljk5IDEwQzE3LjUyIDIyIDIyIDE3LjUyIDIyIDEyUzE3LjUyIDIgMTEuOTkgMnpNMTIgMjBjLTQuNDIgMC04LTMuNTgtOC04czMuNTgtOCA4LTggOCAzLjU4IDggOC0zLjU4IDgtOCA4eiIvPjxwYXRoIGQ9Ik0wIDBoMjR2MjRIMHoiIGZpbGw9Im5vbmUiLz48cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMTIuNSA3SDExdjZsNS4yNSAzLjE1Ljc1LTEuMjMtNC41LTIuNjd6Ii8+PC9zdmc+));
    }
    .right-side {
        margin-top: 5px;
        > *{
            float: none;
            display: inline-block;
            &:first-child {
                margin-top: 0;
            }
        }
    }
    .date-box {
        width: 298px;
        height: 48px;
        padding: 0 8px;
        gap: 16px;
        border-radius: 8px;
        background-color: #2C2C33;
        margin-right: 25px;
        font-size: 16px;
        font-weight: 100;
        position: relative;
        color: #d5d5d5;
        .date-container {
            position: absolute;
            top: 0;
            width: 100%;
        }
        .react-datepicker__triangle {
            left: 50%!important;
        }
        &:after {
            content: "";
            display: inline-block;
            position: absolute;
            right: 5px;
            background: url(/assets/images/down-arrow.svg) no-repeat;
            width: 20px;
            height: 20px;
            vertical-align: sub; 
            background-position: center;
            margin-left: 15px;
            background-size: 12px auto;
            filter: invert(1);
            top: 15px;
        }
        img {
            filter: grayscale(100%) brightness(140%);
        }
        span {
            padding: 12px 0;
            display: inline-block;
            &:last-child {
                float: right;
                margin-right: 20px;
                border-left: solid 1px #000;
                padding-left: 5px;
            }
        }
        .calbutton {
            position: absolute;
            right: 15px;
        }
    }
    .react-datepicker-wrapper {
        opacity: 0;
    }
    #hidden-date {
        width: 0;
        height: 0;
        background-color: transparent;
        border: 0;
        opacity: 0;
    }
//     .dashboard-switch-button {
//         margin-top: 0;
//         row-gap: 20px;
//         margin-right: 50px;
//     }

   

//   .switch-dashboard-button{
// margin-left: 100px;
//   }

.dashboard-switch-button {
  margin-top: 0;
  row-gap: 20px;
  margin-right: 100px;
  // border: 1px solid red;
}

.plyoutBtns{
  border: 16px solid transparent;
  border-image: linear-gradient(45deg, red , yellow);
  border-image-slice: 1;
  height: 120px;
  border-radius: 10px;
}


    .header-search-box {
        display: inline-block;
        width: "100%";
        height: 48px;
        border-radius: 8px;
        background-color: #1A1A1A;
        margin-right: 25px;
        padding-left: 20px;
        input {
            width: 256px;
            height: 48px;
            background-color: #1A1A1A;
            border: 0;
            color: #fff;
        }
        img {
            filter: invert(1);
        }
    }
}

.gradient-border {
  position: relative;
  padding: 22px; /* Adjust padding as needed */
  background: linear-gradient(45deg, #6C1814, #D22F26, #6C1814);
  border-radius: 8px;
  overflow: hidden; 
}

.gradient-border img {
  border-radius: 4px; 
}

.gradient-border::before {
  content: '';
  position: absolute;
  top: 1px; /* Adjust the inset to match your border thickness */
  left: 1px;
  right: 1px;
  bottom: 1px;
  background: #212529; /* Button's original background color */
  border-radius: 6px; /* Slightly smaller than the parent border-radius */
  z-index: 0;
}

.gradient-border > * {
  position: relative;
  z-index: 1; /* Place the inner content above the gradient */
}



}
