/* You can add global styles to this file, and also import other style files */
@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");
@import "./mixins.scss";
@import "./variables.scss";
* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
}

html,
body {
  height: 100%;
}

a {
  text-decoration: none;
}

.deshboard-header {
  background-color: #ffffff;
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 16px rgba(153, 153, 153, 0.08);
  position: relative;
  z-index: 1;
  .logo {
    max-width: 135px;
    display: inline-block;
    vertical-align: middle;
    img {
      max-width: 100%;
    }
  }
  .welcome-name {
    display: inline-block;
    vertical-align: middle;
    padding-left: 80px;
    h5 {
      margin: 0;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
    }
    .date {
      span {
        color: #646464;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

// login page 



.bottom-background {
  position: absolute;
  bottom: 0;
  width: 100%;
  opacity: 0.7; // Adjust opacity if needed
}



.user {
  .user-dropdown {
    .name {
      background: #b9bee9;
      width: 40px;
      height: 40px;
      display: inline-block;
      font-weight: 400;
      font-size: 24px;
      color: #ffffff;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
    }
    a.box {
      padding: 8px 16px;
      display: inline-block;
      &.active {
        background: #efefef;
        border-radius: 8px;
      }

      &:after {
        content: "";
        display: inline-block;
        position: relative;
        background: url(../images/down-arrow.svg) no-repeat;
        width: 20px;
        height: 20px;
        vertical-align: sub;
        background-position: center;
        margin-left: 15px;
        background-size: 12px auto;
      }
    }
    .dropdown {
      background: #ffffff;
      box-shadow: 0px 4px 24px rgba(153, 153, 153, 0.16);
      border-radius: 6px;
      max-width: 155px;
      position: absolute;
      right: 20px;
      opacity: 0;
      visibility: visible;
      transition: all ease-in-out 0.5s;
      margin-top: 10px;
      display: none;
      &.show {
        opacity: 1;
        visibility: visible;
        display: block;
      }
      &:before {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 15px 10px;
        border-color: transparent transparent #ffffff transparent;
        position: absolute;
        top: -10px;
        right: 40px;
      }
      .close {
        padding: 8px 16px;
        display: inline-block;
        font-size: 16px;
        line-height: 100%;
        width: 100%;
        color: #0d0d0d;
        background: #ffffff;
        box-shadow: 0px 8px 24px rgba(153, 153, 153, 0.1);
        border-radius: 6px;
        position: relative;

        &::after {
          content: "";
          background: url(../images/clear.svg) no-repeat;
          width: 21px;
          height: 21px;
          display: inline-block;
          vertical-align: middle;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      ul {
        list-style: none;
        padding: 10px 0;
        margin: 0;
        position: relative;

        li {
          display: inline-block;
          width: 100%;
          a {
            display: flex;
            width: 100%;
            color: #333333;
            padding: 5px 15px;
            align-items: center;
            margin-bottom: 5px;
            img {
              width: 20px;
              margin-right: 10px;
            }
            &:hover {
              background-color: #f1f1f1;
            }
          }
        }
      }
    }
  }
}
.filter-icon {
  position: absolute;
}
.main-content {
  display: flex;
  flex-wrap: wrap;
  height: 100;
  &.dark {
    background-color: $dark_background;
  }
  &.light {
    //  background-color: #f9f9f9;
    background-color: $light_background;
  }
}
.mb-24 {
  margin-bottom: 24px;
}
.react-datepicker__close-icon::after {
  background-color: #868688 !important;
}

.sidebar-menu {
  width: 260px;
  padding: 0 10px;
  // background-color: #ffffff;
  position: fixed;
  top: 0;
  padding-top: 96px;
  height: 100vh;
  transition: all ease-in-out 0.5s;
  box-shadow: 0px 4px 16px rgba(153, 153, 153, 0.08);
  .back-menu {
    margin-bottom: 20px;
    a {
      background: #f2f2f2;
      border: 0.5px solid #e6e6e6;
      border-radius: 6px;
      padding: 6px;
      display: inline-block;
      line-height: 0;
    }
  }
  @at-root .menu-icon#{&} {
    width: 105px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .menu {
    width: 100%;
    ul {
      list-style: none;
      padding: 0;
      li {
        display: inline-block;
        margin-bottom: 20px;
        width: 100%;
        a {
          border-radius: 6px;
          padding: 6px 16px;
          font-weight: 400;
          font-size: 16px;
          // color: #333333;
          display: flex;
          align-items: center;
          text-decoration: none;
          transition: all ease-in-out 0.5s;
          overflow: hidden;

          span {
            margin-left: 5px;
            white-space: nowrap;

            @at-root .menu-icon#{&} {
              display: none;
            }
          }
        }
        &.active,
        &:hover {
          a {
            // color: #ffffff;
            // background: #d22f26;
            box-shadow: 0px 2px 4px rgba(153, 153, 153, 0.08);
            img {
              filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
                saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
            }
          }
        }
      }
    }
  }
  &.menu-icon + .content-body {
    width: calc(100% - 105px);
    margin-left: 105px;
  }
  &.light {
    background-color: $light_navbar_background;
    .back-menu {
      a {
        filter: invert(0);
      }
    }
    .menu {
      ul {
        li {
          a {
            color: $light_sidemenu_text;
          }

          &.active,
          &:hover {
            a {
              color: $light_sidemenu_active_text;
              background: $light_sidemenu_active_background;
            }
          }
        }
      }
    }
  }
  &.dark {
    background-color: $dark_navbar_background;
    .back-menu {
      a {
        filter: invert(1);
      }
    }
    .menu {
      ul {
        li {
          a {
            color: $dark_sidemenu_text;
          }
          &.active,
          &:hover {
            a {
              color: $dark_sidemenu_active_text;
              background: $dark_sidemenu_active_background;
            }
          }
        }
      }
    }
  }
}

.content-body {
  // width: calc(100% - 260px);
 
  margin-left: 260px;
  // padding: 25px 40px;
  padding: 25px 40px 0px 40px;
  width: 100%;
  transition: all ease-in-out 0.5s;
  &.SchedulingDetail {
    padding-left: 0;
    .title-header {
      padding-left: 40px;
    }
    .list-box {
      background: #efe2e2;
      padding: 20px;
      min-height: 96%;
    }
    .list-box-right{
      min-height: 100% !important;
    }
    &.SchedulingLoop {
      // min-height: calc(100vh - 130px);
      // height: calc(100vh);
      height: 100%;
      .dashboard-content {
        height: 100%;
      }
    }
  }
  &.light {
    background-color: $light_background;
  }
  &.dark {
    background-color: $dark_background;
  }
}
.SchedulingDetail {
  .dashboard-content {
    display: flex;
  }
  .left {
    width: 280px;
  }
  .list-box {
    h3 {
      color: #333;
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      + div {
        text-align: center;
        margin-top: 33px;
      }
    }
    input:not(:invalid) {
      width: 200px;
      padding: 8px 16px;
      border-radius: 6px;
      border: 0.5px solid #d22f26;
      background: #fff;
      box-shadow: 0px 2px 4px 0px rgba(153, 153, 153, 0.08);
    }
  }
  .program-list {
    margin-left: 30px;
    margin-top: 16px;
    .treeview {
      .list-group {
        --bs-list-group-bg: transparent;
        --bs-list-group-border-width: 0;
        --bs-list-group-item-padding-x: 0;
      }
      ul {
        padding-left: 0;
      }
    }
  }
  .program-header {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    position: relative;
    padding-left: 18px;
    &::before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background-color: #d22f26;
      left: 0;
      top: 3px;
    }
  }
}
// .sidebar-menu.menu-icon + .content-body {
//   width: calc(100% - 105px);
//   margin-left: 105px;
// }

.breadcrumbs {
  width: 100%;
  ul {
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    li {
      color: #999999;
      font-weight: 500;
      cursor: pointer;
      a {
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;

        transition: all ease-in-out 0.3s;
        &:hover {
          color: #999999;
        }
      }
      &.active {
        color: #000000;
        font-size: 20px;
        color: #333333;
      }
      &::after {
        content: "";
        display: inline-block;
        background: url(../images/arrow_right.svg) no-repeat;
        width: 14px;
        height: 14px;
        vertical-align: middle;
        margin: 0 5px;
      }
      &:last-child::after {
        display: none;
      }
      &:hover {
        color: #000000;
      }
    }
  }
  &.dark {
    ul {
      li {
        color: $dark_heading_text;
        a {
          color: $dark_heading_text;
        }
      }
    }
  }
}
.title-header {
  color: #333;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}
.dashboard-content {
  width: 100%;
  .top-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-side {
      padding-right: 30px;
      h3 {
        margin: 0;
        color: #d9d9d9;
        font-weight: 500;
        font-size: 20px;
        line-height: 100%;
      }
    }
    .right-side {
      .icon-list {
        display: flex;
        flex-wrap: wrap;
        .icon {
          background: #ffffff;
          border: 0.5px solid #e6e6e6;
          border-radius: 6px;
          padding: 6px;
          display: flex;
          align-items: center;
          margin-right: 15px;
          height: 36px;
        }
        .view-list {
          display: flex;
          .icon {
            margin: 0;
            &.grid-icon {
              border-right: 0;
              border-radius: 6px 0 0 6px;
            }
            &.list-icon {
              border-left: 0;
              border-radius: 0px 6px 6px 0px;
            }
            &.active {
              background: #d22f26;
              border-color: #d22f26;
              img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
                  saturate(0%) hue-rotate(288deg) brightness(102%)
                  contrast(102%);
              }
            }
          }
        }
      }
    }
  }
}

.table {
  table {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      text-align: left;
      // background-color: #ffffff;
    }
    td {
      font-size: 14px;
      color: #333333;
      padding: 15px 20px;
      vertical-align: middle;
      height: 60px;
      border-bottom: 1px solid #efe2e2;
    }
    th {
      background: #efe2e2;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: #927f7f;
      padding: 10px 20px;
      height: 48px;
    }
  }
}

.table-pagination {
  background: #f2f2f2;
  border-style: solid;
  border-color: #e6e6e6;
  border-width: 0px 1px 1px 1px;
  padding: 10px;
  display: flex;
  .pagination-count {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .count {
      background: #f2f2f2;
      width: 120px;
      font-weight: 500;
      font-size: 14px;
      color: #333333;
    }
    .pagination-arrow {
      background: #f2f2f2;
      width: 60px;
      display: flex;
      justify-content: space-between;

      .prev {
        width: 20px;
        height: 20px;
        display: inline-block;
        text-align: center;

        &.disable {
          opacity: 0.3;
        }
      }
      .next {
        width: 20px;
        height: 20px;
        text-align: center;
        display: inline-block;

        &.disable {
          opacity: 0.3;
        }
      }
    }
  }
}

.btn-red {
  background: #d22f26;
  border-radius: 8px;
  padding: 13px 24px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  line-height: 20px;
}

.main {
 
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f0f0; 
  display: flex;
  // align-items: stretch;
  flex-wrap: wrap;
  .welcome {
    width: 50%;
    img {
      object-fit: cover;
      height: 100vh;
      width: 100%;
    }
    .welcome-content {
      position: absolute;
      bottom: 10%;
      left: 120px;
      color: #ffffff;
      max-width: 430px;
      &.forget-content {
        p {
          font-family: Roboto;
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;

          span {
            font-family: Roboto;
            font-size: 25px;
            font-weight: 500;
            line-height: 26px;
          }
        }
      }
      img {
        width: auto;
        height: auto;
        margin-bottom: 10px;
      }
      h1 {
        font-family: Roboto;
        font-size: 48px;
        font-weight: 500;
        line-height: 52px;
        letter-spacing: 0px;
        text-align: left;

        // font-size: 48px;
        // line-height: 52px;
        // margin-bottom: 10px;
      }
      p {
        // font-size: 24px;
        // line-height: 34px;

        font-family: Roboto;
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0px;
        text-align: left;
      }
    }
  }

  .login-block {

    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #ffffff; // Light background for the login form
    padding: 30px;
    border-radius: 8px;
    // box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); // Optional shadow for depth
    max-width: 700px;
    width: 100%;
    .login-form {
      max-width: 535px;
      padding: 60px;
      // box-shadow: 0px 8px 24px rgba(112, 144, 176, 0.15);
      border-radius: 17px;
      width: 100%;
      border: 1px solid #FFFFFF52;
    }
  }
}

.login-form {
  .title {
    padding-bottom: 40px;
    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #0d0d0d;
      margin: 0 0 5px;
    }
  }
  .form-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #0d0d0d;
  }
  .form-group {
    margin-bottom: 20px;
    .error {
      color: #ca4f11;
      margin-top: 10px;
      display: inline-block;
      width: 100%;
      font-family: Roboto;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;
    }
    &.error-msg {
      .input-group {
        border-color: #ca4f11;
        box-shadow: 0px 4px 8px rgba(202, 79, 17, 0.16);
      }
    }
    .input-group {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      border: 1px solid #444648;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 6px;
      padding: 13px 20px;
      .input-group-prepend::selection {
        min-width: 21px;
        line-height: 0;
      }
      .form-control {
        border: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #999999;
      }
    }
  }

  .form-check-input:checked {
    background-color: #d22f26;
    border-color: #d22f26;
  }
  .form-check-label {
    color: #272727;
    font-size: 14px;
    line-height: 20px;
  }
  .text-right {
    text-align: right;
    a.forgot {
      color: #ca4f11;
      display: inline-block;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .btn.btn-block {
    background: #d22f26;
    box-shadow: 0px 16px 32px rgba(153, 153, 153, 0.24);
    border-radius: 6px;
    color: #ffffff;
    display: block;
    width: 100%;
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
    padding: 13px 30px;
    font-weight: 500;
    transition: all ease-in-out 0.5s;
    &:hover {
      background-color: #0d0d0d;
      color: #ffffff;
    }
  }
}

.retun-block {
  margin-top: 40px;
  text-align: center;
  p {
    color: #333333;
    font-size: 14px;
    line-height: 20px;
  }
  a {
    color: #d22f26;
    &:hover {
      color: #333333;
    }
  }
}

.smalltext {
  color: grey;
  font-size: 12px;
}
/* Define a style for even rows (1st, 3rd, 5th, etc.) */
table tr:nth-child(even) {
  // background: #f2f2f2 !important;
  background: #ffffff !important;
}

/* Define a style for odd rows (2nd, 4th, 6th, etc.) */
table tr:nth-child(odd) {
  background: #ffffff !important;
}

.dark-theme {
  table tr td {
    background:  rgb(46, 48, 51);
    border-bottom: 1px solid #111111;
    color: #ffffff;
    .scheduleInActive {
      background-color: #1c1c1c;
    }
    .scheduleInActive {
      color: #c3c3c3;
    }
    .scheduleActive {
      background: #161616;
    }
    .edit-icon img{
      filter: invert(0.75);
    }
  }
  .table thead {
    border-color: #1c1c1c;
    th {
      background: #262728;
      color: #c3c3c3;
      border-color: #1c1c1c;
    }
  }
  .channel-form form .form-group input[type=text] {
    background: #262728;
    border-color: #19191a;
    width: 100%;
  }
  .table-pagination, .pagination-count .count, .pagination-count .pagination-arrow {
    background: #262728!important;
    border-color: #19191a;
    color: #c3c3c3;
    img {
      filter: invert(0.8);
    }
  }
  .store-front-data {
    background-color: #262728!important;
  }
  .dashboard-content .store-front-data .feature-tab-wrapper .tab-wrapper-shadow .tab-item-wrapper {
    background: #262728;
    border-color: #19191a;
    color: #c3c3c3;
  }
  .dashboard-content .store-front-data .page-content .store-front-table {
    th {
      background: #19191a;
      color: #c3c3c3;
      img {
  
      }
    }
    td {
      color: #ffffff;
    }
  }
  .dashboard-content .store-front-data .page-content .channel-listing-cseq {
    background: #2e3033 !important;
    border-bottom: 1px solid #111111;
    color: #ffffff;
    .list-wrapper .list-item {
      &:hover {
        background: #19191a;
      }
      .channel-name {
        color: #ffffff;
      }
    }
  }
  .search-wrapper {
    background-color: #1a1a1a !important;
    img {
      filter: invert(1);
    }
    input {
      background-color: #1a1a1a !important;
      color: #ffffff;
    }
  }
  .title-header, .fc-toolbar-title {
    color:#c3c3c3;
  }
  .fc tr[role=presentation] [role=row] th {
    border-color: #19191a;
  }
  .fc-theme-standard td, .fc-theme-standard th, .fc-theme-standard .fc-scrollgrid {
    border-color: #000;
  }
  .content-body.SchedulingDetail .list-box {
    background: #262728;
    input{
      background: #000;
      color: #fff;
    }
  }
  .fc .fc-timegrid-axis, .fc [role=columnheader], .custom-fc-header, .fc-schedule-duration {
    // background: #000;
    background: #1A1A1A;
    color: #c3c3c3;
  }
  .treelist, .SchedulingDetail .list-box h3, .SchedulingDetail .program-header {
    color: #c3c3c3;
  }
  .schedule-butttons button {
    background: #efe2e2;
  }
  .copy-btn, .fc-header-toolbar .fc-toolbar-chunk:first-of-type .fc-prev-button, .fc-header-toolbar .fc-toolbar-chunk:first-of-type .fc-next-button {
    filter: invert(1);
  }
  .rdg, .rdg-cell:not([role="columnheader"]) {
    background: #262728;
    border-color: #19191a;
    color: #fff;
    input {
      background: #000;
    }
    select {
      filter: invert(1);
    }
  }
  .rdg-header-row {
    background: #000;
  }
  [role=columnheader] {
    border-color: #19191a;
  }
  .select-grid-header {
    background-color: #1c1c1c;
    border-color: #1c1c1c;
  }
  .options-list-wrapper {
    .options-list {
      background-color: #1c1c1c;
      color: #c3c3c3!important;
    }
  }
  .table-checkbox table tr {
    border-bottom: unset;
  }
  .refresh-button {
    // background-color: #e4e4e2;
    // border-color: #e4e4e2;
  }
  .react-time-picker__clear-button, .errorimg, .channel-arrow {
    filter: invert(1);
  }
  .error-content {
    background-color: #1c1c1c;
    color: #c3c3c3!important;
  }
  .dashboard-content .store-front-data .page-content .channel-listing-cseq .list-wrapper .item-wrapper .nexted-list .nexted-list-item {
    .program-name {
      color: #c3c3c3;      
    }
    &:hover .program-name {
      color: rgb(51, 51, 51);
    }
  }
  .store-front-table {
    background: #2e3033 !important;
    border-color: #111111!important;
    tr {
      background: #2e3033 !important;
      border-bottom: 1px solid #111111!important;
      td {
        background: unset !important;
        border-bottom: unset;
      }
    }
  }
  .dashboard-content .store-front-data .page-content .store-front-table {
    .date-range {
      background: #2e3033;
      border-color: #111111;
      color: #c3c3c3;
      .from-date, .to-date {
        color: #c3c3c3;
      }
    }
    .img-icon {
      background: #111111;
    }
  }
  .modal-content {
    background: #121212;
    border: 1px solid #FFFFFF52;
    border-radius: 20px;
    // padding: 54px;
padding-left: 54px;
padding-right: 54px;
padding-top: 25px;
padding-bottom: 25px;
    width: 666px;
  justify-self: center;
  }
  .modal-title  {
    color: #c3c3c3;
  }
  .btn-close {
    filter: invert(1);
  }
}

.default-odd {
  display: inline-flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: #faeae9;
  color: #d22f26;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  border: none;

  &:hover {
    color: #d22f26;
    // background: #D22F26;
    border: 1px solid #d22f26;
  }
}
.default-even {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 16px */
  display: inline-flex;
  padding: 14px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background: #d22f26;
  border: none;

  &:hover {
    color: #d22f26;
    background: #fff;
    border: 1px solid #d22f26;
  }
}
.grey-text16 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #a4a0a0;
}
a {
  cursor: pointer;
}

/*=================== Boostrap Overwrite ====================*/
.btn {
  font-size: 16px;
  height: 44px;
  display: flex;
  padding: 7px 20px;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}
.btn-primary {
  background-color: #d22f26;
  border-color: #d22f26;

  &:hover,
  &:active,
  &:focus,
  &.active,
  &:hover:not(.btn-check),
  &:first-child:active {
    background-color: #c32b22;
    border-color: #c32b22;
  }

  &:disabled {
    background-color: #d22f26;
    border-color: #d22f26;
  }
}

.btn-danger {
  background-color: #faeae9;
  border-color: #faeae9;
  color: #d22f26;

  &:hover,
  &:active,
  &:focus,
  &.active,
  &:hover:not(.btn-check),
  &:first-child:active {
    background-color: #c32b22;
    border-color: #c32b22;
    color: #ffffff;
  }

  &:disabled {
    background-color: #faeae9;
    border-color: #faeae9;
    color: #d22f26;
  }
}

.btn-secondary {
  background-color: transparent;
  border-color: #d22f26;
  color: #d22f26;

  &:hover,
  &:active,
  &:focus,
  &.active,
  &:hover:not(.btn-check),
  &:first-child:active {
    background-color: #c32b22;
    border-color: #c32b22;
    color: #ffffff;
  }

  &:disabled {
    background-color: transparent;
    border-color: #d22f26;
    color: #d22f26;
  }
}

/*=================== Boostrap Overwrite ====================*/

/*=================== CMS table start here ==================*/
.tenant-content, .tenant-add {
  .setting-genre {
    span {
      // background-color: #737373;
    }
    .active {
      &:after {
        content: unset;
      }
    }
  }
  .dropdown-menu {
    background-color: #2e3033;
    border-color: #3f3f3f;    
    box-shadow: 0px 4px 16px rgba(153, 153, 153, 0.08);
    .dropdown-item {
      color: #fff;
      padding: 15px 8px 8px 15px;
      &:last-of-type {
        padding-bottom: 15px;
      }
      img {
        filter: invert(0.75);
        margin-right: 15px;
      }
      &:hover {
        background-color: #000;
      }
    }
  }
  .box__input {
    width: 196px;
    &:before {
      width: 168px;
    }
    input {
      left: 0;
      width: 100%;
      height: 100%;
      top: 0;
    }
    .inputFile[type="file"] {
      height: 100%;
    }
  }
  .upload-box-split {
    .form-col:first-child {
      padding-left: 5px;
    }
    .form-col:nth-child(2) {
      margin-left: -50px;
    }
  }
  &.tenant-page {
    .setting-genre {
      span{
        &:nth-child(2) {
          &:after {
            left: 156px;
          }
        }
        &:nth-child(3) {
          &:after {
            left: 180px;
          }
        }
      }
    }
    .form-container .form-field label {
      font-size: 14px;
    }
  }
  .btn-add {
    color: #D9D9D9;
    font-size: 14px;
    text-decoration: underline;
  }
  .checkbox-container {
    input[type="checkbox"] {
      appearance: none!important;
      background-color: transparent!important;
      border: 1px solid #cacece!important;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 6px!important;
      border-radius: 3px!important;
      display: inline-block;
      position: relative;
      + span {
        top: -2px;
        position: relative;
      }
    }
    
    input[type="checkbox"]:checked:after {
     // content: '\2714';
      content: ' ';
      font-size: 14px;
      position: absolute;
      top: 0;
      left: 0;
      color: #99a1a7;
      width: 14px;
      height: 14px;
      background: url(/assets/images/checkbox-active.svg) no-repeat;
    }
  }
  .multiselect-field .optionListContainer li:hover {
    color: #fff!important;
  }
  .form-container {
    overflow: hidden;
  }
}
.btn-grey {
  background-color: #444649;
}
.transcoding-profile {
  margin-bottom: 10px;
  span {
    background: rgb(0, 0, 0);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    color: #c3c3c3;
    -moz-border-radius: 5px;
    height: 41px;
    font-size: 14px;
    margin-left: 5px;
    line-height: 28px;
    border-color: #19191a !important;
    display: inline-block;
    padding: 8px 18px;
    img {
      height: 20px;
      margin-top: -3px;
    }
  }
}

.cms-table-list {
  width: 100%;
  border: none;
  border-collapse: collapse;

  th {
    background: #efe2e2;
    padding: 14px 15px;
    font-size: 14px;
    color: #927f7f;
    font-weight: 500;
    line-height: 20px;

    label {
      font-size: 14px;
      color: #927f7f;
      font-weight: 500;
      line-height: 20px;
      padding-right: 5px;
    }

    span {
      cursor: pointer;
    }

    &:first-child {
      padding-left: 30px;
    }

    &:last-child {
      padding-right: 30px;
    }
  }

  td {
    background: #ffffff;
    padding: 18px 15px;
    font-size: 14px;
    color: #333333;
    font-weight: 400;
    line-height: 24px;
    border-bottom: 1px solid #efe2e2;

    &:first-child {
      padding-left: 30px;
    }

    &:last-child {
      padding-right: 30px;
    }
  }

  .table-action-group {
    white-space: nowrap;
    display: flex;
    align-items: center;

    .action-label {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      margin: 0px;
      padding: 0 10px;
    }

    .table-icon {
      margin: 0 10px;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .icon-group {
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;

    &:last-child {
      padding-right: 0px;
    }

    &:first-child {
      padding-left: 0px;
    }
  }
  &.tenant-table {
    padding: 25px 0;
    border-radius: 6px;
    margin-top: 15px;
    th {
      background-color: #efe2e2;
      color: #927f7f;
    }
    td {
      position: relative;
    }
    .dropdown button {
      border: none;
      &:active, &:hover, &.show {
        background-color: transparent;
      }
    }
    .dropdown-menu.show {
      transform: translate(0px, 32px) !important;
    }
  }
}
.super-tenant-form {
  margin-top: 50px;
  h3 {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
  }
  .btn-grey {
    padding: 7px 10px;
  }
  table {
    th {
      text-transform: uppercase;
    }
  }
  input {
    color: #fff;
  }   
  .actions img{
    filter: invert(1);
    &:nth-child(2) {
      filter: invert(0.75);
      margin-left: 20px;
    }
  }
  div[role=gridcell] {
    height: 46px;
    line-height: 46px;
  }
  .rdg-row {
    height: 46px!important;
  }
  .rdg-cell:not([role=columnheader]) {
    border-color: transparent;
  }
  [role=columnheader] {
    background-color: #444649;
    border-color: transparent;
  }
  .gridDropdown {
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 22px;
    padding: 5px;
  }
}
.onboarded {
  background: #C1DBCC;
  display: inline-block;
  padding: 5px 6px;
  border-radius: 12px;
  line-height: 16px;
  height: 24px;
  color: #2E3033;
}
.expired {
  display: inline-block;
  padding: 5px 6px;
  border-radius: 12px;
  line-height: 16px;
  height: 26px;
  background: rgba(255, 249, 234, 0.25);
  border: solid 1px rgba(254, 242, 214, 0.5);
  color: #FEF2D6;
  margin-left: 10px;
  width: 160px;
  text-align: center;
  &.red {
    background-color: rgba(251, 174, 174, 0.19);
    border-color: rgba(255, 164, 159, 0.5);
    color: #FFA49F
  }
  &.orange {
    background-color: rgba(255, 213, 163, 0.28);
    border-color: 0.5px solid rgba(255, 197, 130, 0.5);
    color: #F9C78D;
  }
}
.btn-list {
  height: 30px;
  margin: 0 auto;
}
.dark-theme {
  .tenant-add {
    .setting-genre {
      span {
        position: relative;
        margin-left: 34px;
        &:first-child {
          margin-left: 0;
        }
        &:after {
          width: 50px;
          height: 1px;
          border-bottom: dashed 1px #A3A3A3;
          content: " ";
          top: 16px;
          position: absolute;
          z-index: 55;
          left: 122px;
          border-color: #a3a3a3 !important;
        }
        &:last-child:after {
          content: unset;
        }
      }
      .active {
        background-color: #3f3f3f;
        border-color: #686868;
        color: #fff;
      }
    }
  }
  .content-body {
    .dropdown-menu {
      background-color: #2e3033;
      border-color: #3f3f3f;    
      box-shadow: 0px 4px 16px rgba(153, 153, 153, 0.08);
      .dropdown-item {
        color: #fff;
        padding: 15px 8px 8px 15px;
        &:last-of-type {
          padding-bottom: 15px;
        }
        img {
          filter: invert(0.75);
          margin-right: 15px;
        }
        &:hover {
          background-color: #000;
        }
      }
    }
  }
  .tenant-content{
    .setting-genre {
      span {
        border-color: #686868;
        background-color: #0D0D0D;
        color: #999;
        position: relative;
        margin-left: 34px;
        &:first-child {
          margin-left: 0;
        }
      }
      .active {
        background-color: #3f3f3f;
        border-color: #686868;
        color: #fff;
        &:after {
          content: unset;
        }
      }
    }
    .action-dropdown {
      position: relative;
      > a{
        background-color: #d22f26;
        border-color: #d22f26;
        font-size: 16px;
        height: 44px;
        display: flex;
        padding: 7px 20px;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        border-radius: 6px;
        color: #ffffff;
      }
    }
    .dropdown-content {
      background: #2E3033;
      border-radius: 6px;
      margin-top: 30px !important;
      width: 100%;
      overflow: hidden;
      &.active {
        display: block;
      }
      button {
        width: 100%;
        background-color: #2E3033;
        color: #ffffff;
        &:hover {
          background-color: #454749;
        }
      }
    }
  }
  .breadcrumbs ul li {
    &:hover {
      color: #fff;
    }
  }
  .cms-table-list {
    th {
      background: #262728;
      label {
        color: #c3c3c3;
      }
    }
    &.tenant-table th {
      background-color: #444649;
      color: #BFBFBF;
      cursor: pointer;
    }
  }
  .table-icon {
    filter: invert(0.75);
  }
  .search-bar {
    border-color: #19191a;
    input, button.search-btn {
      background: #262728;
    }
  }
  
  // generic css below move to style.scss if possible
  .multiselect-field .searchWrapper {
      // background: #2e3033!important;
      border-color: #19191a!important;
      color: #ffffff;
     .chip {
          background-color: #1c1c1c!important;
          color: #c3c3c3 !important;
     }
  }
  .bg-light {
      background-color: #121212 !important;
  }
  .breadcrumbs {
      li {
          a {
              color: #c3c3c3;
          }
      }
  }
  .video-title, .detail-label {
      color: #c3c3c3;
  }
  .detail-inputs {
      background: #1c1c1c!important;
      border-color: #19191a;
      color: #c3c3c3;
      &:focus {
          color: #ffffff;
      }
  }
  .right-bar {
      background: #1c1c1c;
      border-color: #19191a;
      .right-head {
          color: #c3c3c3;
      }
  }
  .form-container h4, .form-field {
    color: #c3c3c3;
    label {
      color: #c3c3c3;
    }
    input, textarea {
      background: #2e3033 !important;
      border-color: #19191a !important;
      color: #ffffff!important;
      &:focus {
        color: #ffffff!important;
      }
    }
    select {
      background-color: #2e3033 !important;
      border-color: #19191a !important;
      color: #7C7C7C!important;
      options {
        color: #7C7C7C!important;
      }
    }
    .tagInput-field {
      .rti--container {
        background-color: #2e3033 !important;
        border-color: #19191a !important;
        color: #ffffff!important;
      }
      .tag-chip {
        background-color: #1c1c1c !important;
        color: #c3c3c3 !important;      
      }
    }
  }
  .form-control, .react-datepicker-wrapper input {
    background: #2e3033  !important;
    border-color: #8E9192 !important;
    color: #ffffff;
    &[disabled] {
      background: #1c1c1c;
    }
    &:focus {
      color: #ffffff!important;
    }
  }
  .btn-primary.invalid {
    background-color: #1c1c1c;
    color: #5a5a5a;
  }
  .react-datepicker, .react-datepicker__header {
    background: #2e3033;
    border-color: #19191a;
    color: #ffffff;
  }
  .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header,
  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #fff;
  }
  .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover {
    background-color: #000;
  }
  .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #2e3033;
  }
  .file-uploader-container {
    .file-uploader-device-list ul li {
      background-color: #000 !important;
      border-color: #19191a !important;
      color: #ffffff!important;
      &.active {
        border-color: #D22F26!important;
        &:after {
          border-color: transparent transparent #000000 transparent;
        }
      }
      img {
        filter: invert(0.75);
      }
    }
    .file-uploader-body {
      background: #000 !important;
      border-color: #19191a !important;
      color: #ffffff!important;
    }
    .imageexist a {
      background-color: transparent;
      img {
        filter: invert(0.75);
      }
    }
  }
  .image-button {
    span {
      background-color: #000 ;
      border-color: #19191a !important;
      color: #ffffff !important;
      &.active {
        border-color: #d22f26!important;
        background-color: #000!important;
        &:after {
          border-color: transparent transparent #000000 transparent;
        }
      }
    }
  }
  .box-shadow.mt-30 {
    background: #000 !important;
    border-color: #19191a !important;
    color: #ffffff !important;
    > .box__input {
      &:before {
      //  background-color: #000 !important;
      }
    }
  }
  .form-container .form-field > select {
    &:active, &:focus {
      color: #8e9192;      
    }
  }
  .multiSelectContainer ul, .partner-card .dropdown-menu.show {
    background: #000 !important;
    border-color: #cccccf !important;
    color: #ffffff !important;
    z-index: 9999; 
    position: absolute!important;
    visibility: visible !important;

    .dropdown-item {
      color: #ffffff;
      &:hover {
        // background-color: #1c1c1c !important;
        overflow: visible !important;
      }
    }
  }
}
.light-theme {
  .tenant-content{
    .action-dropdown {
      position: relative;
      > a{
        background-color: #d22f26;
        border-color: #d22f26;
        font-size: 16px;
        height: 44px;
        display: flex;
        padding: 7px 20px;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        border-radius: 6px;
        color: #ffffff;
      }
    }
    .dropdown-content {
      background: #f4e0dd;
      border-radius: 6px;
      margin-top: 30px !important;
      width: 100%;
      overflow: hidden;
      &.active {
        display: block;
      }
      button {
        width: 100%;
        background-color: #ffc8c8;
        color: #ffffff;
        &:hover {
          background-color: #f4e0dd;
          // background-color: #c4c4c4;
        }
      }
    }
  }
}
.view-mode-main{ 
  margin-left: auto;
  > span {
    display: inline-block;
    width: 36px;
    height: 36px;
    vertical-align: middle;
    text-align: center;
    border: solid 1px #E6E6E6;
    border-radius: 5px;
    background-color: #ddd;
    cursor: pointer;
    &.active {
      background-color: #F9F1F0;
      border-color: #D22F26;
    }
    &:first-child {
      margin-right: 8px;
    }
    img {
      height: 18px;
      width: auto;
      margin-top: 8px;
    }
  }
}

.view-mode {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  > span {
    display: inline-block;
    width: 36px;
    height: 36px;
    vertical-align: middle;
    text-align: center;
    border: solid 1px #E6E6E6;
    border-radius: 5px;
    background-color: #ddd;
    cursor: pointer;
    &.active {
      background-color: #F9F1F0;
      border-color: #D22F26;
    }
    // &:first-child {
    //   margin-right: 8px;
    // }
    img {
      height: 18px;
      width: auto;
      margin-top: 8px;
    }
  }
}

.p-b-80 {
  padding-bottom: 80px!important;
}
.white {
  color: #ffffff!important;
}
/*=================== CMS Table End Here ====================*/

/*=================== Switch Check Start Here ===============*/
.switch-check {
  display: flex;
  align-items: center;
  position: relative;

  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
  }

  label {
    cursor: pointer;
    text-indent: 50px;
    width: 42px;
    height: 24px;
    background: #ffe2e1;
    display: block;
    border-radius: 12px;
    position: relative;
    line-height: 26px;
  }

  label:after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 14px;
    height: 14px;
    background: #d22f26;
    opacity: 0.5;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + label {
    background: #4fc17e;
  }

  input:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
    background: #ffffff url(/assets/images/switch-check.svg) no-repeat;
    opacity: 1;
    width: 20px;
    height: 20px;
    top: 2px;
    background-position: center;
  }
  .label {
    color: #333333;
    font-weight: 400;
    font-size: 14px;
    margin-left: 8px;
  }
}
/*=================== Switch Check End Here =================*/

/*=================== Form Container Start Here ===============*/

.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;

  h4 {
    font-weight: 700;
    font-size: 24px;
    margin: 0px;
    padding-bottom: 15px;
    width: 100%;
  }

  .form-field {
    width: 100%;
    display: flex;
    flex-direction: column;

    input:not([type="radio"]), input:not([type="checkbox"]),
    select,
    & > textarea,
    .react-datepicker__input-container input {
      height: 48px;
      width: 100%;
      @include border-radius(6px);
      border: 1px solid #e6e6e6;
      padding: 12px 16px 12px 16px;
      color: #8e9192;
      font-size: 16px;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #ffffff;

      &:focus,
      &:active {
        color: var(--bs-body-color);
        background-color: var(--bs-body-bg);
        border-color: #86b7fe;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
      }

      &:disabled {
        background: #efebeb;
        border-color: #efebeb;

        &:focus,
        &:active {
          border-color: #efebeb;
        }
      }
    }
    .split-box {
      select {
        padding-right: 20px;
        background-position: calc(100% - 5px) center !important;
      }
      .form-col {
        &:last-of-type {
          padding-left: 5px;
        }
        &:first-of-type {
          padding-right: 5px;
          padding-left: 50px;
        }
      }
    }
    .radio-form {
      label {
        width: auto;
        padding-left: 30px;
        cursor: pointer;
        position: relative;
         // Before pseudo-element for the box
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 20px;
          height: 20px;
          border: 2px solid #D9D9D9;
          background: none;
          border-radius: 100%;
        }

        // After pseudo-element for the checkmark
        &::after {
          content: "";
          position: absolute;
          left: 5px;
          top: 5px;
          width: 10px;
          height: 10px;
          border: solid #D9D9D9;
          border-width: 5px;
          border-radius: 100%;
          display: none;
        }
      }
      input {
        display: none;
        &:checked + label {
          &::after {
            display: block;
          }
        }
      }
    }
    .checkbox-container {
      input {
        width: auto;
        appearance: auto;
        height: auto;
      }
    }
    .multiselect-field {
      .searchWrapper {
        border: 1px solid #e6e6e6;
        background: #ffffff;
        @include border-radius(6px);
        display: flex;
        align-items: center;
        min-height: 48px;
        padding: 7px 13px 2px 13px;
        flex-wrap: wrap;

        .chip {
          background: rgba(210, 47, 38, 0.14);
          color: #2e3132;
          @include border-radius(5px);
          height: 31px;
          display: flex;
          align-items: center;
          font-size: 16px;
          margin-left: 5px;

          i {
            margin-left: 10px;
            cursor: pointer;
          }
        }

        input {
          margin: 0px;
          padding: 10px;
          height: 48px;
          margin-bottom: 5px;
          border: none;
          outline: none;
          font-size: 16px;
          color: #8e9192;
        }

        .icon_down_dir {
          right: 15px;

          &::before {
            display: none;
          }
        }
      }

      .optionListContainer {
        li {
          &.highlightOption {
            background: #d22f26;
          }

          &:hover {
            background: #d22f26;
          }
        }
      }
    }

    .tagInput-field {
      .rti--container {
        padding: 7px 18px;
        border: 1px solid #e6e6e6;
        @include border-radius(6px);
        gap: 10px;
        min-height: 48px;

        &:focus-within {
          color: var(--bs-body-color);
          background-color: var(--bs-body-bg);
          border-color: #86b7fe;
          outline: 0;
          box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        }
      }

      .tag-chip {
        background: rgba(210, 47, 38, 0.14);
        color: #2e3132;
        @include border-radius(5px);
        height: 31px;
        display: flex;
        align-items: center;
        font-size: 16px;
        padding: 4px 10px;
        display: flex;
        align-items: center;
        line-height: normal;

        span {
          line-height: normal;
        }

        button {
          font-size: 10px;
          color: #9d9c9c;
          padding-right: 0px;

          &:hover {
            color: #9d9c9c;
          }
        }
      }

      .tag-input {
        margin: 0px;
        padding: 0px;
        height: 31px;
        border: none;
        outline: none;
        font-size: 16px;
        color: #8e9192;
        width: auto;
      }
    }

    select {
      background-image: url(../images/dropdown-arrow.svg)!important;
      background-repeat: no-repeat!important;
      background-position: calc(100% - 15px) center!important;
    }

    .date-field {
      display: flex;
      margin-left: -10px;
      margin-right: -10px;

      & > * {
        flex-grow: 1;
        margin: 0 10px;
      }
    }

    label {
      font-size: 16px;
      font-weight: 700;
      width: 100%;
      padding-bottom: 5px;

      i {
        font-style: normal;
        color: #d22f26;
      }
    }

    .field-length {
      text-align: right;
      width: 100%;
      color: #828282;
      font-size: 14px;
      padding-top: 3px;
    }

    .field-info {
      width: 100%;
      color: #828282;
      font-size: 14px;
      padding-top: 3px;
      padding-left: 16px;
    }

    .field-error {
      width: 100%;
      font-size: 14px;
      color: #d22f26;
      padding-top: 3px;
      padding-left: 16px;
      font-weight: 500;
    }

    .field-info-row {
      display: flex;
      width: 100%;

      .field-error {
        flex-grow: 3;
      }
    }

    &.hasError {
      & > input,
      & > select,
      & > textarea,
      .react-datepicker__input-container input,
      .tagInput-field .rti--container,
      .multiselect-field .searchWrapper {
        border-color: #d22f26;
      }
    }
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.img-40 {
  width: 40px;
  height: auto;
}
.select-grid-header {
  background-image: url(../images/dropdown-arrow.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 5px) center;
  border: 1px solid #e6e6e6;
  padding: 4px 6px 4px 6px;
  margin-left: 18px;
  border-radius: 6px;
  min-width: 106px;
  color: #8e9192;
  font-size: 12px;
  text-transform: uppercase;
  outline: none;
  appearance: none;
  background-color: #ffffff;
}
.form-row {
  margin-left: -50px;
  margin-right: -50px;
  display: flex;
  flex-wrap: wrap;

  .form-col {
    width: 50%;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;

    &.full-form-col {
      width: 100%;
    }
  }
}
.text-normal {
  font-weight: normal!important;
}
.text-12 {
  font-size: 12px!important;
}
.button-row {
  margin-left: -10px;
  margin-right: -10px;
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin: 0 10px;
  }
}
/*=================== Form Container End Here =================*/
.multiDropCell,
.rdg-cell {
  overflow: visible;
}
.small-grey-text {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
  color: gray;
}
.part-height {
  height: 540px;
  overflow-y: auto;
  /* Change the color of the track (the area behind the thumb) */
}

.part-height::-webkit-scrollbar {
  width: 3px; /* Set the width of the scrollbar */
}

.part-height::-webkit-scrollbar-track {
  background: #f1f1f1; /* Set the background color of the track */
}

/* Change the appearance of the scrollbar thumb (the draggable part) */
.part-height::-webkit-scrollbar-thumb {
  background: #000000; /* Set the background color of the thumb */
  border-radius: 6px; /* Add rounded corners to the thumb */
}

/* Change the appearance when the scrollbar is hovered over */
.part-height::-webkit-scrollbar-thumb:hover {
  background: #e0e0e0;
}
.part-heights {
  min-height: 25vh;
  max-height: 640px;
  overflow-y: auto;
  /* Change the color of the track (the area behind the thumb) */
}

.part-heights::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */

}

.part-heights::-webkit-scrollbar-track {
  background: #E9E9E9 /* Set the background color of the track */
}

/* Change the appearance of the scrollbar thumb (the draggable part) */
.part-heights::-webkit-scrollbar-thumb {
  background:  #606264;; /* Set the background color of the thumb */
  border-radius: 6px; /* Add rounded corners to the thumb */

  
}

/* Change the appearance when the scrollbar is hovered over */
.part-heights::-webkit-scrollbar-thumb:hover {
  background: #e0e0e0;
}

.div-flex-space-between {
  display: flex;
  justify-content: space-between;
}
.hidden {
  display: none;
}
.p-l-0 {
  padding-left: 0!important;
}
.p-r-0 {
  padding-right: 0!important;
}
.m-b-10 {
  margin-bottom: 10px;
}
.r1upfr807-0-0-beta-41 {
  background: $light_background !important;
  color: #333333 !important;
}
.rdg-cell:focus,
.rdg-cell:active {
  background: $light_background !important;
  color: #333333 !important;
}

.rnvodz57-0-0-beta-41::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.rnvodz57-0-0-beta-41::-webkit-scrollbar
{
	  width: 10px;
    height: 10px;
	background-color: #F5F5F5;
}

.rnvodz57-0-0-beta-41::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #a7a7a7;
    cursor: pointer; 
}
/* Custom checkbox styles */
.custom-checkbox {
  position: relative;
  display: flex;
  align-items: center;
}

.checkbox-input {
  position: absolute;
  opacity: 0; /* Hide the native checkbox */
}

.checkbox-label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 14px;
  color: #BFBFBF;
}

.checkbox-label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #BFBFBF;
  background-color: #403a3a;
  border-radius: 4px;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}



.checkbox-input:checked + .checkbox-label:after {
  content: '✔';
  position: absolute;
  top: 2px;
  left: 4px;
  color: white;
  font-size: 12px;
  line-height: 18px;
  transition: color 0.2s ease;
}


