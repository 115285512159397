// #121212

@import "../../assets/css/variables.scss";

.dashboard-content {
  width: 100%;

  .top-content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-side {
      padding-right: 30px;

      h3 {
        margin: 0;
        color: #d9d9d9;
        font-weight: 500;
        font-size: 20px;
        line-height: 100%;
      }
    }

    .right-side {
      .icon-list {
        display: flex;             
        align-items: center;    
        flex-wrap: nowrap;        
    
        .search-container {
          flex-grow: 1;
          margin-right: 10px;
          border: 1px solid #FFFFFF29;
          background: none;
          border-radius: 6px;
      }
       
        
    
        .add-icon {
          margin-left: 10px;    
        
        }
    
        .view-list {
          display: flex;
    
          .icon {
            // margin-left: 10px;    // Adds space between view icons
            
            
    
            &.grid-icon {
              border-right: 0;
              border-radius: 6px 0 0 6px;
    
              &:last-child {
                border-radius: 0 6px 6px 0;
              }
            }
    
            &.list-icon {
              border-left: 0;
              border-radius: 0px 6px 6px 0px;
            }
    
            &.active {
              background: #d22f26;
              border-color: #d22f26;
    
              img {
                filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
                  saturate(0%) hue-rotate(288deg) brightness(102%)
                  contrast(102%);
              }
            }
          }
        }
      }
    }
    
  }
  &.dark {
    .top-content {
      .left-side {
        h3 {
          color: $dark_heading_text;
        }
      }
      .right-side {
        .icon {
          background: $dark_icon;
        }
      }
    }
    // .search-box {
    //   background-color: $dark_background !important;
    
    //   img {
    //     filter: invert(1);
    //   }
    //   input {
    //     background-color: $dark_background !important;
    //     color: $dark_heading_text;
    //     &::placeholder {
    //       color: $dark_heading_text;
    //     }
    //   }
    // }
  }
  // &.light {
  //   .top-content {
  //     .left-side {
  //       h3 {
  //         color: $light_heading_text;
  //       }
  //     }
  //     .right-side {
  //       .icon {
  //         background: $light_icon;
  //       }
  //     }
  //   }
  //   .search-box {
  //     background-color: $light_background;
  //     img {
  //       filter: invert(0);
  //     }
  //     input {
  //       color: $light_heading_text;
  //     }
  //   }
  // }

  .search-container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%; // Set to your desired width
    border: 1px solid #FFFFFF29;
    background: none;
    border-radius: 6px;
    margin-right: 16px; 
  
    .search-box {
      flex: 1;
      padding: 10px 40px 10px 20px; 
  
      border-radius: 4px; 
      background-color: #f9f9f9;
      color: #333; 
      font-size: 16px; 
      transition: border-color 0.3s;
  
      &:focus {
        outline: none; 
        border-color: #f20818; 
      }
  
      &::placeholder {
        color: #aaa;

        border-radius: 4px;
      }
    }
  
    .search-button {
      position: absolute;
      right: 10px; 
      background: transparent; 
      border: none;
cursor: pointer; 
&.dark-mode .search-icon {
  filter: brightness(0) invert(1);}


  
      img {
        width: 20px; // Set icon size
        height: auto; // Maintain aspect ratio
      }
  
      &:hover {
        opacity: 0.7; 
      }
    }
  }
  
}

.folder-list {
  padding: 20px 0;
  // width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  .floder-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    flex-direction: column;
    text-align: center;
    border: 0.5px solid transparent;
    position: relative;
    width: 305px;
    cursor: pointer;

    input[type="checkbox"] {
      // display: none;
      @at-root .editable#{&} {
        display: block;
        opacity: 0;

        & + label::before {
          content: "";
          display: inline-block;
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 2px;
          border: 1px solid #a9acae;
          right: 4px;
          top: 4px;
        }

        &:checked + label::before {
          background-color: #d22f26;
          border-color: #d22f26;
        }

        &:checked + label::after {
          content: "";
          display: inline-block;
          color: #ffffff;
          position: absolute;
          right: 6px;
          top: 7px;
          font-weight: bold;
          background: url(/assets/images/check-icon.png) no-repeat;
          width: 8px;
          height: 6px;
        }
      }
    }

    span {
      display: inline-block;

      &.icon {
        margin-bottom: 3px;
        
      }

      &.label {
        color: #333333;
        font-size: 16px;
        line-height: 100%;
      }
    }
  }

  input {
    position: absolute;
    top: 0;
    right: 0px;
  }

  .dropdown-menu.show {
    padding: 0;
    border: solid 1px #f2f2f2;
    .dropdown-item {
      padding: 8px 12px;
      font-size: 16px;
      line-height: 24px;
    }
    img {
      padding: 2px 4px;
      width: 22px;
      height: 18px;
      border-radius: 4px;
      border: 0.333px solid #e6e6e6;
      margin-right: 8px;
    }
  }
}

.action-category {
  width: 100%;

  ul {
    padding: 0;
    list-style: none;
    display: flex;
    gap: 24px;

    li {
      display: inline-block;
      position: relative;

      a {
        color: #999999;
        padding: 8px 16px;
        // border: 0.5px solid #e6e6e6;
        border-radius: 6px;
        display: inline-block;
        background: #ffffff;
      }

      &.active {
        a {
          // border-color: #D22F26;
          box-shadow: 0px 4px 16px rgba(153, 153, 153, 0.08);
          color: #333333;
          font-weight: 500;
        }
      }
    }
  }
}

.table-checkbox {
  table {
    width: 100%;
    border-collapse: collapse;
    

    thead {
      tr {
        &::before {
          background: #efe2e2;
        }
      }
    }

    tr {
      position: relative;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      transition: all 0.3s;
      border-collapse: separate;
      // border-bottom: 3px solid #f9f9f9;

      &:last-child {
        border-bottom: none;
      }

      &.active {
        &::before {
          background-color: #d22f26;
        }
      }

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 0px;
        height: 100%;
        background-color: #fff;
      }
    }

    tbody {
      tr {
        &:hover {
          z-index: 1;
          box-shadow: 0px 16px 24px 0px rgba(41, 11, 78, 0.16);

          &::after {
            content: "";
            width: 1px;
            position: absolute;
            top: 2px;
            bottom: 0;
            left: 0px;
            height: 95%;
            width: 5px;
            /* Adjust the width as needed */
            background: linear-gradient(
              to bottom,
              #d22f26,
              #d22f26,
              #d22f26,
              #d22f26
            );
          }

          /* Create a linear gradient for the right border */
          &::before {
            content: "";
            position: absolute;
            top: 2px;
            bottom: 2px;
            right: 0;
            width: 1px;
            height: 95%;
            background: linear-gradient(
              to bottom,
              #22bbbe,
              #7dad41,
              #f4b719,
              #e55824,
              #b52c80
            );
           
          }

          td {
            // background: #fff ?:#110e0e;
            // background: appTheme === "dark" ? '#ffffff' : 'black'
            /* Set the background of the element to transparent */
            border-radius: 0;
            border: none;
            /* Create a linear gradient for the top border */
            position: relative;

            

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              height: 1px;
              /* Adjust the height as needed */
              background: linear-gradient(
                to right,
                #22bbbe,
                #7dad41,
                #f4b719,
                #e55824,
                #22bbbe
              );
              
            }

            /* Create a linear gradient for the bottom border */
            &::after {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
              height: 1px;
              /* Adjust the height as needed */
              background: linear-gradient(
                to right,
                #22bbbe,
                #7dad41,
                #f4b719,
                #e55824,
                #22bbbe
              );
              &.active {
                a {
                  // border-color: #D22F26;
                  box-shadow: 0px 4px 16px rgba(228, 16, 16, 0.08);
                  color: #e81010;
                  font-weight: 500;
                }
              }
            }

            /* Create a linear gradient for the left border */
            &:last-child {
              text-align: center;
            }

            &:first-child {
              text-align: center;
            }
          }
        }
      }
    }

    th,
    td {
      text-align: center;
      vertical-align: top;
      // background-color: #ffffff;
    }

    td {
      color: #333;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      vertical-align: middle;
      height: 60px;
      font-size: 14px;
      color: #333333;
      // padding: 15px 10px;
      border-bottom: 1px solid #efe2e2;

      &:last-child {
        text-align: center;
      }

      .dropdown button {
        cursor: pointer;
        width: 24px;
        text-align: center;
        background: transparent;
        border: 0;
      }
    }

    th {
      // background: #efe2e2;
      padding: 10px 10px;
      vertical-align: top;
      color: #927f7f;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.24px;
      text-transform: uppercase;
      vertical-align: middle;
    }

    input[type="checkbox"] {
      display: none;

      & + label {
        position: relative;
      }

      & + label::before {
        content: "";
        display: inline-block;
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        border: 1.5px solid #a9acae;
        left: 0px;
        top: 0px;
      }

      &:checked + label::before {
        background-color: #d22f26;
        border-color: #d22f26;
      }

      &:checked + label::after {
        content: "";
        display: inline-block;
        color: #ffffff;
        position: absolute;
        left: 5px;
        top: 6px;
        font-weight: bold;
        background: url(/assets/images/checkbox-active.svg) no-repeat;
        width: 18px;
        height: 16px;
      }
      &:checked + .check::after {
        content: "-";
        display: inline-block;
        color: #ffffff;
        position: absolute;
        left: 0px;
        top: 0px;
        font-size: 16px;
        font-weight: bold;
        background: none;
        width: 18px;
        height: 18px;
      }
    }
  }
}

.listTable {
  input[type="checkbox"] {
    opacity: 1 !important;
    position: unset !important;
  }
}

.table-pagination .pagination-count .pagination-arrow {
  background: #f2f2f2;
  width: 100px;
  display: flex;
  justify-content: space-between;
}

/* Style for the dropdown menu */
.dropdown-menu {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
}

/* Style for dropdown items */
.dropdown-item {
  padding: 5px;
  color: #333;
  text-decoration: none;
  display: block;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}

/* Style for the three-dot icon in the dropdown toggle button */
.three-dots {
  font-size: 20px;
  line-height: 1;
  color: #fff;
  background: transparent;

  &:hover {
    background-color: transparent;
  }
}

.custom-dropdown-menu {
  position: absolute;
  z-index: 9999 !important; /* Ensure it stays above everything else */
  top: 100%;
  left: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-menu {
  font-family: Lato;
  font-size: 14px;
  font-weight: 700;
  line-height: 15px;

  text-align: left;
  padding: 5px;
  min-width: auto;
  padding: 0px;
  position: absolute;
  inset: 0px auto auto -70px !important;
  transform: translate(0px, 40px);

  img {
    width: 14px;
  }
}

.dropdown-toggle::after {
  content: none;
}

.dropdown-toggle {
  padding: 0%;
}

.card-header {
  padding: 0 0 8px 0;
  background: transparent;
  border: none;
  position: relative;

  button {
    background: transparent;
    cursor: pointer;
    border: navajowhite;
    font-weight: 700;
    width: 16px;
    height: 16px;
    &.btn.show,
    &:hover,
    &:active {
      background: transparent !important;
    }
  }
}

.search-box {
  border: 1px solid #999999; 
 border-radius: 6px;
  
  input {
    &:focus-visible {
      outline: none;
    }
  }
  &.mr-16 {
    margin-right: 16px;
  }
}

.card {
  padding: 10px;
  text-align: left;
  background: #f7f7f7; // Light mode background
  transition: all 0.3s;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);

  &:hover {
    background: linear-gradient(#f7f7f7, #f7f7f7) padding-box,
      linear-gradient(to right, #22bbbe, #7dad41, #f4b719, #e55824, #b52c80)
        border-box;

        
    border-radius: 6px;
    border: 1px solid transparent;
    box-shadow: 14px 14px 12px 1px rgba(14, 24, 39, 0.16);
    transform: translateY(-4px);
  }

  &.dark {
    // background: #202124; 

    &:hover {
      background: linear-gradient(#212223, #2c2b2b) padding-box,
      linear-gradient(to right, #22bbbe, #7dad41, #f4b719, #e55824, #b52c80)
        border-box;
      border-radius: 6px;
      border: 1px solid transparent;
      box-shadow: 14px 14px 12px rgba(0, 0, 0, 0.5);
      transform: translateY(4px);
    }
  }

  .card-body {
    padding: 8px 4px 8px 4px;
    border-radius: 6px;
    // background: #ffffff;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .card-header {
    .partner-card .dropdown-menu.show {
      padding: 0;
      .dropdown-item {
        padding: 8px 12px;
      }
    }
  }
  &.dark {
    background: #2E3033;
    border:1px solid #FFFFFF29;
    height: 50;
    // .card-body {
    //   background: $dark_card_background;
    // }
    
    .card-header {
      color: $dark_heading_text;
      .partner-card {
        img {
          filter: invert(1);
        }
      }
    }
    .card-body {
      background: $dark_card_body;
      b {
        color: $dark_heading_text;
      }
      p {
        span {
          span {
            b {
              color: $universal_black;
            }
          }
        }
      }
    }
    .m-1 {
      span {
        // background: #444648;
        background:  #262728;
        color: $dark_heading_text;
        padding: 30;
        // width: 100%;
        
      }
    }
  }
  &.light {
  
    .card-header {
      color: $light_heading_text;
      .partner-card {
        img {
          filter: invert(0);
        }
      }
    }
    .card-body {
      background: $light_card_body;
      border: 1px solid #e1e1e1;
      box-shadow: rgb(200, 208, 231) 5.2px 5.2px 8px -4px inset, rgb(255, 255, 255) -3.2px -3.2px 8px 0px inset;
      b {
        color: $light_heading_text;
      }
    }
    .m-1 {
      span {
        background: $light_card_body;
        color: #2a2a2a;
      }
    }
    .chip-second1,
    .chip-second2,
    .chip-second3 {
      border: 1px solid #d6d6d6;
      width: 100%;
      padding:8;
      margin-left: 2;
      background-color:#2a2a2a;
      color: #fff;
    
   
    }

  }
}
.chip-prog {
  display: inline-block;          
  border-radius: 5px;           
  text-align: center;            
  word-wrap: break-word;          
  white-space: normal;           
  overflow: hidden;              
  text-overflow: ellipsis;        
  box-sizing: border-box;         
  background-color: #f0f0f0;    
  color: #000;                 
  max-width: 100%;                
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 10px; 
  max-width: 100%; 
  overflow: hidden; 
  box-sizing: border-box; /* Ensures padding and margin are included in width calculation */
}

.chip-container {
  display: flex;
  justify-content: center; /* Centers content in each column */
}

.chip-prog {
  word-wrap: break-word;
  white-space: normal;
  padding: 5px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 8px; /* Optional: Add rounded corners */
  display: flex;
  justify-content: space-between;
  align-items: center;
}


@media (max-width: 768px) {
  .chip-prog {
    font-size: 10px;              /* Reduce text size */
  }
}

/* Adjustments for medium screens */
@media (min-width: 768px) and (max-width: 1200px) {
  .chip-prog {
    font-size: 12px;              /* Slightly larger text size */
  }
}

/* Adjustments for larger screens */
@media (min-width: 1200px) {
  .chip-prog {
    font-size: 14px;              /* Larger text size for large screens */
  }
}

/* Add extra-large screen support */
@media (min-width: 1600px) {
  .chip-prog {
    font-size: 14px;
  }
}




.chip-transcode {
  // border: 1px solid #c8ebff;
  // background: #7B07D7;
  color:#FFFFFF;
 padding: 5px;
  border-radius: 3px;
  font-weight: 900;

}

.chip-validation {
  // border: 1px solid #dddcff;
  // background: #0749D7;
  color:#FFFFFF;
  padding: 5px;
  margin-left: 10px;
  border-radius: 3px;
  font-weight: 900;
}

.chip-publish {
  // border: 1px solid #fed9d7;
  // background: #01766A;
  color:#FFFFFF;
  padding: 5px;
  margin-left: 10px;
  border-radius: 3px;
  font-weight: 900;
  
}

.chip-second1 {
  font-family: Roboto;
  font-size:  12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding: 4px 8px;
  border-radius: 5px;



  b {
    margin-left: 5px;
  }
}

.chip-second2 {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin: 0px 4px;
  padding: 4px 8px;
  border-radius: 5px;



  b {
    margin-left: 5px;
  }
}

.chip-second3 {
  font-family: Roboto;
  font-size:  12px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding: 4px 8px;
  border-radius: 5px;

 

  b {
    margin-left: 5px;
  }
}

.partner-card {
  .dropdown-menu.show {
    display: inline-flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    transform: translate(35px, 24px) !important;
  }
}

/* For large screens (1920px and above) */
@media (min-width: 1920px) {
  .partner-card-column {
    width: 25%; /* 4 columns (each 25% width) */
  }
}

/* For medium to large screens (1495px to 1919px) */
@media (min-width: 1495px) and (max-width: 1919px) {
  .partner-card-column {
    width: 33.33%; /* 3 columns (each 33.33% width) */
  }
}

/* For tablets and smaller screens (992px to 1495px) */
@media (max-width: 992px) {
  .partner-card-column {
    width: 50%; /* 2 columns (each 50% width) */
  }
}

/* For mobile screens (less than 576px) */
@media (max-width: 576px) {
  .partner-card-column {
    width: 100%; /* 1 column (100% width) */
  }
}


.dropdown {
  position: absolute;
  right: 4px;
  top: 5px;
  &.action-dropdown {
    position: unset;
    top: unset;
    right: unset;
    .dropdown-content {
      margin-top: 18px;
    }
  }
}

.tab-buttons .tab {
  font-size: 16px;
  color: #e6e6e6;
  cursor: pointer;
  padding: 10px;
  position: relative;
  border-bottom: 2px solid #e6e6e6;
}

/* Active Tab Styles for Light Mode */
.tab-buttons .tab.active {
  border-bottom: 2px solid #d22f26;
  color: #d22f26;
}





/* Active Tab Styles for Dark Mode */
@media (prefers-color-scheme: dark) {
  .tab-buttons .tab.active {
    border-bottom: 2px solid #d22f26; /* Red border in dark mode */
    color: #d22f26; /* Red text in dark mode */
  }
}



