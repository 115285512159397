.channel-form {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    align-items: center;
    &.w-1200 form {
      max-width: 1200px;
    }
    form {
        width:100%;
        // max-width: 800px;
        max-width: 900px;
        .form-group {
            position: relative;
            width: 100%;
            input[type="text"] {
                width: 100%;
                background: #F4F2F2;
                border: 1px solid #E6E6E6;
                border-radius: 6px;
                color: #999999;
                font-weight: 400;
                font-size: 14px;
                padding: 13px 24px;
            }
            ::-webkit-input-placeholder {  
                color: #999999;
                opacity: 1;
              }
              
              :-ms-input-placeholder {  
                color: #999999;
                opacity: 1;
              }
              
              ::placeholder {
                color: #999999;
                opacity: 1;
              }
            button[type="submit"] {
                background-color: transparent;
                border: none;
                position: absolute;
                top: 50%;
                right: 10px;
                transform: translateY(-50%);
                cursor: pointer;
                img {
                  width: 24px;
                  height: 24px;                }
            }
        }
    }
}
.Scheduling{
    .table, .table-pagination {
        width:100%;
        // max-width: 900px;
    }
    .w-1200 {
        // max-width: 1300px;
        th[width="55%"] {
          width: 65%;
        }
        th[width="30%"] {
          width: 20%;
        }
    }
    tr {
      >th, td {
        &:last-child {
          text-align: right;
        }
      }
      td {
        cursor: pointer;
        b {
          color: #5e5e5e;
          text-transform: none;
        }
        .edit-icon img {
          width: 24px;
          height: 24px;
        }
        .list-view-icon img{
          width: 40px !important;
          height: 40px !important;
        }
        .list-view-btn {
          margin-right: 68px;
          margin-left: 12px;
        }
      }
    }
}
.fc {
  
  tr[role="presentation"] [role="row"] th {
    // border: 5px solid #fff;
    padding: 0px 3px;
    &:last-child {
      border-right: 0;
    }
  }
  .fc-timegrid-axis {
    padding: 2px 4px;
    background: #EFE2E2;
    .fc-timegrid-axis-frame {
      justify-content: flex-start;;
    }
  }
}
.fc [role=columnheader] {
  background: #EFE2E2;
}
.fc-col-header-cell-cushion {
  padding: 4px 6px;
  display: inline-block;
  width: 100%;
  text-align: left;
}
.custom-fc-header-title {
  // border-bottom: 1px solid #DAD0D0;
  font-size: 20px;
  &:after {
    content: '';
    display: block;
    clear: both;
  }
  .fc-event {
    float: right;
  }
}
.fc-schedule-duration {
  color: #454545;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  background: #262728 !important;
  padding: 3px;
  border-radius: 8px;
  .flex {
    justify-content: space-between;
    margin-top: 5px;
    // font-size: 8px;
    font-size: 12px;
    &:first-of-type {
      // font-size: 8px;
      font-size: 12px;
    }
  }
}
.fc-header-toolbar {
  .fc-toolbar-chunk:first-of-type {
    div, h2 {
      display: inline-block;
    }
    .fc-prev-button, .fc-next-button{
      background-color: transparent;
      border-color: transparent;
      color: #0D0D0D;
      &:focus {
        box-shadow: none;
      }
    }
    h2 {
      font-size: 16px;
      line-height: 42px;
      vertical-align: middle;
      margin-top: 5px;
    }
  }
  button.fc-button-primary, button.fc-today-button:disabled {
    background-color: #db4747;
    border-color: #db4747;
    text-transform: capitalize;
  }
}
.custom-fc-header {
  color: #0D0D0D;
}
.fc-timegrid-event-harness {
  inset-inline-start: 0!important;
  inset-inline-end: 0!important;
  &:not(.main-event) {
    height: 2px;
  }
}
.copy-btn {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url(../../assets/images/solar_copy-outline.png);
}
.undo-btn {
  width: 12px;
  height: 12px;
  display: inline-block;
  background-image: url(../../assets/images/paste.png);
  background-size: 12px 12px;
}
.program-header {
  cursor: pointer;
  &:not(.list-active) {
    padding-bottom: 10px;
    + .treelist{
      display: none;
    }
  }
}
.treelist {
  list-style-type: none;
  padding-left: 0!important;
  margin-left: 3px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  float: unset!important;
  background: transparent!important;
  border-width: 0!important;
  .fc-event {
    margin: 2px 0 0 0!important;
  }
  li {
    line-height: 30px;
    vertical-align: middle;
    padding-left: 20px;
    border-left: 1px dashed #A9ACAE;
    margin-top: 2px!important;
    &.active {
      border-left: 1px solid #D22F26;
    }
  }
  ul {
    list-style-type: none;
    padding-left: 0;
    display: none;
    &.block {
      display: block;
    }
  }
}
.schedule-butttons {
  button {
    display: inline-flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 0.5px dashed #D09753;
    background: rgba(255, 213, 174, 0.40);
    width: 110px;
    margin: 0 8px 12px 0;
    &.schedule-promos {
      border: 0.5px dashed #53BD9D;
      background: #C3E5DB;
    }
    &.schedule-fillers {
      border: 0.5px dashed #0D0D0D;
      background: #CDCDCD;
    }
  }
}
// .scheduling-main-table {
//     tbody {
//         tr {
//             &:nth-of-type(odd) { 
//                 td {
//                     background-color: #F9F9F9;
//                 }
//             }
//             &:nth-of-type(even) { 
//                 td {
//                     background-color: #EFEFEF;
//                     .scheduleInActive {
//                       background-color: #F9F9F9;
//                     }
//                 }
//             }
//         }
//     } 
// }

.scheduling-main-table tbody tr:nth-of-type(odd) td {
  background-color: #F9F9F9;
}
.scheduling-main-table tbody tr:nth-of-type(even) td {
  background-color: #EFEFEF;
}
.scheduleInActive {
  background-color: #F9F9F9;
}

.dark-theme .scheduling-main-table tbody tr:nth-of-type(odd) td,
.dark-theme .scheduling-main-table tbody tr:nth-of-type(even) td {
  background-color: #2e3033 !important;
}
.dark-theme .scheduleInActive {
  background-color: #1c1c1c ;
}
// .dark {
//   .scheduling-main-table {
//       tbody {
//           tr {
//               &:nth-of-type(odd) { 
//                   td {
//                       background-color: #2e3033 !important;
//                   }
//               }
//               &:nth-of-type(even) { 
//                   td {
//                       background-color: #2e3033 !important;
//                       .scheduleInActive {
//                         background-color: #1c1c1c !important;
//                       }
//                   }
//               }
//           }
//       } 
//   }
// }

/* Add this CSS in a separate stylesheet or in a CSS module in your React component */

/* Style for the external event container */
/* Place your custom CSS styles here */

body {

    font-size: 14px;
    font-family: "Lucida Grande", Helvetica, Arial, Verdana, sans-serif;
  }
  
  #wrap {
    width: 1500px;
    margin: 0 auto;
  }
  
  #external-events ul{
    float: left;
    width: 220px;
    padding: 0 10px;
    border: 1px solid #ccc;
    background: #eee;
    text-align: left;
    max-height: 300px;
    overflow-y: auto;
  }

  #external-events ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
  }

  #external-events ul::-webkit-scrollbar {
	  width: 12px;
	  // background-color: #F5F5F5;
  }

  #external-events ul::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #c2c2c2;
  }

  #external-events h4 {
    font-size: 16px;
    margin-top: 0;
    padding-top: 1em;
  }
  
  #external-events .fc-event {
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 10px 0;
    cursor: pointer;
    white-space: nowrap;
  }
  
  #external-events p {
    margin: 1.5em 0;
    font-size: 11px;
    color: #666;
  }
  
  #external-events p input {
    margin: 0;
    vertical-align: middle;
  }
  #external-events + .right {
    width: calc(100%);
  }
  
  #calendar {
    float: right;
    width: calc(100%);
  }
  
  .mb-20 {
    margin-bottom: 20px;
  }
  .fc .fc-timegrid-slot {
    height: 3em;
  }
  .custom-event {
    color: #0D0D0D;
    font-weight: 500;
    padding: 0;
    border-radius: 5px;
    cursor: pointer;
    height: 100%;
    > div:first-child {
      padding-left: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    button{
      position: absolute;
      top: 1px;
      right: 4px;
    
    &:hover{
        background-color: #1e5b83;
        color: white;
    }
}
  }
  
  .custom-event-element .fc-event-main {
    /* Add custom sizing styles here */
    height: auto; /* You can set a specific height or 'auto' to adjust automatically */
  }
  .delete-btn-schedule {
    width: 14px;
    height: 16px;
    display: inline-block;
    background-image: url(../../assets/images/delete_forever.png);
    background-color: transparent;
    background-size: 15px;
    position: absolute;
    border: none;
  }
  .ad-schedule {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #ffae00;
    position: absolute;
    right: 22px;
    top: 2px;
    font-size: 8px;
    text-align: center;
  }
.scheduleActive, .scheduleHalfActive, .scheduleInActive {
  border-radius: 6px;
  background: #F0F6F3;
  padding: 6px;
  display: inline-block;
  margin-right: 12px;
  color: #10552D;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  img {
    position: absolute;
    width: 8px;
    height: 8px;
    right: 0;
    bottom: -4px;
  }
}
.scheduleHalfActive {  
  background: #F6EBE7;
  color: #E96C45;
}
.scheduleInActive {
  color: #333;
  background: #EFEFEF;
  cursor: not-allowed;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event.selected-event  {
  max-height: 1px;
  box-shadow: none;
  overflow: hidden;
}
.fc-timegrid-col-events > div.main-event{
    .fc-v-event {
      // background-color: #F2F8DB!important;
      // border-color: #d4deb0!important;
      border-width: 1px;
    }
    &.one .fc-v-event {
      background-color: #FFC374 !important;
      // border-color: #FFC374!important;
    }
    &.two .fc-v-event {
      background-color: #4AD2C9 !important;
      // border-color: #4AD2C9!important;
    }
    &.three .fc-v-event {
      background-color: #8E73EF !important;
      // border-color: #8E73EF!important;
    }
    &.four .fc-v-event {
      background-color: #536EFF !important;
      // border-color: #536EFF!important;
    }
}
.text-right {
  text-align: right;
}

.schedule-loop-view {
  .fc-timegrid-slot-label, .fc-timegrid-slot-label {
    display: none;
  }
  .left {
    // width: 420px;
    width: 265px;
  }
  .middle {
    // width: calc(100% - 1020px);
    width: calc(100% - 800px);
    display: flex;
    min-width: 300px;
  }
  #external-events ul {
    width: unset;
  }
  .right {
    // width: 600px;
    width: 550px;
    .list-box {
      .title-header {
        font-size: 14px;
        text-align: left;
        padding-left: 0;
        &.h2 {
          font-size: 18px;
          text-align: center;
        }
      }
      h5 {
        font-size: 12px;
      }
    }
  }
  .fc-toolbar-chunk {
     > div:first-child, button[title="Today"], [title="Zoom out"] {
      display: none;
     }
     
  }
  [role="presentation"] {
    width: 100%!important;
  }
}
.scheduleBtnNone {
  display: none !important;
}

.fc-scroller {
  overflow-y: auto;
}

.fc-scroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.fc-scroller::-webkit-scrollbar {
  width: 12px;
  // background-color: #F5F5F5;
}

.fc-scroller::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #c2c2c2;
}

table tbody td .text-nowrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100px;
}

.fc-timegrid-body {
  min-width: 102%;
}

.AdsPopup_adsLabel__54axX {
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
// .fc-day {
//   width: 240px;
// }

.fc-timegrid-col-events .fc-timegrid-event-harness .fc-event .fc-event-main {
  height: 40px !important;
  background: rgba(255, 255, 255, 0.65) !important;
}
.fc-timegrid-event-harness-inset .fc-timegrid-event {
  box-shadow: none !important;
}
.fc-timegrid-event-harness,
.fc-event{
  transition: border 0.1s linear;
}
.fc-timegrid-event-harness:hover {
  border: 1px solid transparent;
  border-image: linear-gradient(to right, #22bbbe, #7dad41, #f4b719, #e55824, #b52c80) 1;
}
.fc-timegrid-event-harness:hover .fc-event {
  border: 4px solid #000 !important;
}
.fc-scrollgrid-sync-inner {
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #444649;
    border-bottom: none;
}
.fc-timegrid-col-frame {
  border-radius: 0px 0px 8px 8px;
  border: 1px solid #444649 !important;
  border-top: none !important;
  z-index: 1;
}
.fc-timegrid-col {
  padding: 0px 3px !important;
}