@import "../../../assets/css/variables.scss";
.modal {
  --bs-modal-width: 900px;
}
.flex {
  display: flex;
  .flex-50 {
    flex-basis: 50%;
  }
  .flex-100 {
    flex-basis: 100%;
  }
}
.mt-30 {
  margin-top: 30px;
}
.image-button {
  span {
    padding: 8px 16px;
    border-radius: 6px;
    background: #fff;
    border: 0.5px solid #e6e6e6;
    cursor: pointer;
    margin-right: 8px;
    display: inline-block;
    box-shadow: 0px 4px 16px 0px rgba(153, 153, 153, 0.08);
    position: relative;
    &.active {
      border-color: #d22f26;
      color: #333;
      &:after {
        content: " ";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 12px 12px 12px;
        border-color: transparent transparent #ffffff transparent;
        position: absolute;
        top: 56px;
        left: calc(50% - 12px);
        filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.1));
        -webkit-filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.1));
      }
    }

    // &.active {
    //   border: 0.5px solid #D22F26;
    // }
    &.last-child {
      margin-right: 0;
    }
  }
}
.box-shadow {
  box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.12);
}
/* FolderPopup.css */
.modal-dialog {
  width: 900px;
  height: 735px;

  /* Adjust the maximum width as needed */
}

.modal-content {
  border-radius: 0;
}

.modal-header {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;

  text-align: left;
  border: none;
}

.modal-title {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

.modal-body {
  padding: 0px 20px;
  label {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    margin: 10px 0 8px 0;
    text-align: left;
  }
  input {
    padding: 12px 16px;
    border-radius: 6px;
    border: 1px solid #e6e6e6;
  }
  input::placeholder {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    color: #8e9192;
  }
  .react-datepicker-wrapper input {
    border: 1px solid #dee2e6;
    margin: 0px 20px;
    text-align: left;
    padding: 0.375rem 0.75rem;
    width: 100%;
    &:focus {
      color: var(--bs-body-color);
      background-color: var(--bs-body-bg);
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }
  }
}
.modal {
  .Add {
    width: 77px;
    background: #d22f26;
    padding: 14px, 24px, 14px, 24px;
    border-radius: 8px;
    border: none;
    &.invalid {
      cursor: not-allowed;
      background-color: #ffeae3;
    }
  }
  .Cancel {
    margin-right: 30px;
    padding: 14px, 24px, 14px, 24px;
    border-radius: 8px;
    background: #faeae9;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    border: none;
    text-align: left;
    color: #d22f26;
    transition: all 0.6s;
    &:hover {
      background: #f4d8d7;
    }
  }
  .modal-footer {
    margin-top: 15px;
    justify-content: left;
    border: none;
  }
  .btn-close {
    font-size: 12px;
  }
}
/* Style for the time select input */
.react-datepicker-time__input-container {
  display: inline-block;
  width: 80px; /* Adjust the width as needed */
  margin-right: 10px; /* Add spacing between the date and time inputs */
}

/* Style for the time input */
.react-datepicker-time__input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  text-align: center; /* Center the text */
}

/* Style for the time select arrow (down arrow) */
.react-datepicker-time__input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  fill: #000; /* Color of the arrow */
}

/* Style for the time select dropdown container */
// .react-datepicker__time-container {
//   display: flex;
//   justify-content: center;
// }

/* Style for the time select dropdown */
.react-datepicker__time {
  max-width: 100px; /* Adjust the width as needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  font-size: 14px;
}

/* Style for the time select options */
.react-datepicker__time-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Style for individual time select option */
.react-datepicker__time-list-item {
  cursor: pointer;
  padding: 8px;
  text-align: center;
}

/* Style for the selected time option */
.react-datepicker__time-list-item--selected {
  background-color: #007bff; /* Background color of the selected option */
  color: #fff; /* Text color of the selected option */
}

/* Hover style for time select option */
.react-datepicker__time-list-item:hover {
  background-color: #f2f2f2;
}

.starttime {
  width: 45% !important;
}
.prefixcode {
  width: 11%;
  margin-right: -7px;
  padding: 12px 5px !important;
}
.field-length {
  text-align: right;
  width: 100%;
  color: #828282;
  font-size: 14px;
  padding-top: 3px;
}
