// .FilesDragAndDrop {
//     .FilesDragAndDrop__area {
//       width: 300px;
//       height: 200px;
//       padding: 50px;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       flex-flow: column nowrap;
//       font-size: 24px;
//       color: #555555;
//       border: 2px #c3c3c3 dashed;
//       border-radius: 12px;
  
//       .area__icon {
//         font-size: 64px;
//         margin-top: 20px;
//       }
//     }
//   }
.box__input {
    outline: 2px dashed #ff0000;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    width: 116px;
    height: 96px;
    border-radius: 15px;
    display: inline-block;
    background: url(../../assets/images/uil_upload.svg) no-repeat center top 20px;
    position: relative;
    cursor: pointer;
    &:before {
      content: "";
      position: absolute;
      background-color: rgba(227, 157, 133, 13%);
      z-index: 1;
      width: 88px;
      height: 68px;
      top: 14px;
      left: 14px;
      border-radius: 6px;
    }
    input {
      width: 70%;
      height: 70%;
      opacity: 0;
      overflow: hidden;
      position: relative;
      left: 15%;
      top: 15%;
      border-radius: 15px;
      z-index: 55;
    }
    .box-label {
      text-align: center;
      font-size: 10px;
      position: absolute;
      top: 50px;
      width: 100%;
      z-index: 5;
    }
    .box-size {
      text-align: center;
      font-size: 10px;
      position: absolute;
      top: 65px;
      width: 100%;
      z-index: 5;
    }
  }
  .imageexist{
    // outline: 2px dashed #ff0000;
  
    width: 116px;
    height: 96px;
    border-radius: 15px;
    display: inline-block;
   
    position: relative;
    cursor: pointer;
  
    padding: 7px;
    .image-show{
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  a{
    line-height: 1.3;
    background-color: white;
    position: absolute;
    right: 10px;
    top: 10px;
    img{
      width: 15px;
      height: 15px;
    }
  }
  input {
    width: 70%;
    height: 70%;
    opacity: 0;
    overflow: hidden;
    position: relative;
    // left: 15%;
    // top: 15%;
    border-radius: 15px;
    z-index: 55;
  }
    // &:before {
    //   content: "";
    //   position: absolute;
    //   background-color: rgba(227, 157, 133, 13%);
    //   z-index: 1;
    //   width: 88px;
    //   height: 68px;
    //   // top: 14px;
    //   // left: 14px;
    //   border-radius: 6px;
    // }
  }
  