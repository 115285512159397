/* Map container styles */
.map-container {
    width: 100%;
    height: 100%;
  }
  
  /* Ensure the map takes up the entire container */
  .react-simple-maps {
    width: 100%;
    height: 100%;
  }
  svg{
    width: 100%;
  position: relative !important;  }


