/* validatepop.css */

.validate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}
.validation-container {
  width:760px;
  height: auto;
  position: relative;
  padding: 30px;
}

.content-body.light .validation-container {
  background: white; /* Background for light theme */
}

/* Dark Theme */
.content-body.dark .validation-container {
  background: #1e1e1e; /* Background for dark theme */
}
.ml-2 {
  margin-left: 0.5rem!important;
}
.missing {
  color: #ff5050;
  font-weight: 600;
}
.float-right {
  float: right;
}
.validation-text {

  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  line-height: 28px;
  word-wrap: break-word;
  margin: 10px 0px;
}
.content-body.light .validation-text{
  color: #000; /* Background for light theme */
}

/* Dark Theme */
.content-body.dark .validation-text {
  color: #fff; /* Background for dark theme */
}

.episode-text {
 
  // color: #333333;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  line-height: 28px;
  word-wrap: break-word;
}
.content-body.light .episode-text {
  color: #000; /* Background for light theme */
}

/* Dark Theme */
.content-body.dark .episode-text {
  color: #fff; /* Background for dark theme */
}

.done-button {
cursor: pointer;
  background: #D22F26;
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.done-text {
  color: white;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 16px;
  word-wrap: break-word;
}

.cancel-button {
  cursor: pointer;
  width: 100px;
  padding: 14px;
  margin-right: 20px;
  background: #FAEAE9;
  border-radius: 8px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.cancel-text {
  width: 51px;
  text-align: center;
  color: #D22F26;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  line-height: 16px;
  word-wrap: break-word;
}
.file-section{
  margin:10px 10px;
padding: 15px 10px;
.videos-label{
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  
}
  .status-text{
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    width: 80%;
    color: #8E9192;
    margin: 3px 0px;
    &.success {
      color: #10c192;
      background: none;
    }
  }
}

.content-body.light .videos-label {
  color: #000; /* Background for light theme */
}

/* Dark Theme */
.content-body.dark .videos-label {
  color: #fff; /* Background for dark theme */
}
/* Add class names and their styles for other elements */
.status-icon-shape{
  display: inline-flex;
padding: 4px 6px;
justify-content: flex-end;
align-items: center;
gap: 4px;
border-radius: 12px;

span{

font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 100%; /* 16px */
}
}
.success{
    color: #10552D;
  background: #F0F6F3;
}
.error{
  background: #FFEEE7;
  color: #C2592E;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 100%; /* 16px */
}
.danger{
  color: #FFF;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 100%; /* 16px */

background: red;
}
