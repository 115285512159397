// RightDrawer.scss
.drawer {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 600px;
    background-color: #1A1A1A;
    transition: right 0.3s ease;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
  
  .drawer.open {
    right: 0;
  }
  
  .drawer-content {
    padding: 20px;
  }
  
  .close-btn {
    font-size: 24px; 
    background: #444649; 
    border: none;
    cursor: pointer;
    color: #fff; 
    position: absolute; 
    right: 10px; 
    z-index: 10; 
    padding: 5px 10px; 
    border-radius: 5px; 
    transition: 0.3s; 
  }
  
  .close-btn:hover {
    background: #555; 
  }