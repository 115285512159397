.duplicate-wrapper {
  position: absolute;
  height: 220px;
  background: #fff;
  bottom: 15px;
  left: 80%;
  border-radius: 5px;
  width: 180px;
  box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.2);
  .title {
    font-size: 16px;
    line-height: 16px;
    padding: 5px 10px;
    text-align: left;
    font-weight: 500;
    // background-color: red;
  }
  .list {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 60%;
    overflow: auto;
    .list-item {
      padding: 2px 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      .checkbox {
        width: 15px;
        height: 15px;
        border: 1px solid #000;
        // margin-top: 4px;
      }
    }
  }
  .button-wrapper-small {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
    padding: 5px;
    .button-container-small {
      width: 75px;
      height: 30px;
      background: #faeae9;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .button-text {
        color: #d22f26;
        font-size: 16px;
        font-family: Roboto;
        font-weight: 500;
        line-height: 1;
        word-wrap: break-word;
      }

      &:hover {
        background: #d22f26;
        .button-text {
          color: #ffffff;
        }
        .arrow {
          color: #ffffff;
        }
      }
    }
  }
}
