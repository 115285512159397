.partnermodal {
    :global table {
        margin-top: 25px;;
    }
}
.validForm {
    
}

.adsTimeEditor {
    text-align: center;
    border: dashed 2px #ccc;
    padding: 25px;
    background: #fffafa;
    display: flex;
    justify-content: center;
    :global input {
        height: 20px;
        border: 0;
    }
    :global .react-time-picker__wrapper {
        border-radius: 5px;
        border-color: #ccc;
        padding-left: 5px;
        background-color: #fff;
    }
    :global .react-time-picker {
        text-align: center;
    }
    :global {
        input, input:focus-visible  {
            border-width: 0!important;
            outline: none;
        }
    }

}
.adsTimeFirstChild {
    margin-right: 20px;
}
.editButton {
    cursor: pointer;
}
.adsLabel {
    display: block;
    + div {
        display: block;
        max-width: 200px;
    }
}
.btnInvert img {
    filter: invert(0.75);
}