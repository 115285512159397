.file-uploader-container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .file-uploader-device-list {
        width: 100%;
        display: flex;
        padding-bottom: 10px;

        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
                list-style: none;
                min-height: 40px;
                padding: 7px 16px;
                border:1px solid #E6E6E6;
                background: #ffffff;
                border-radius: 6px;
                -moz-border-radius: 6px;
                -webkit-border-radius: 6px;
                display: flex;
                align-items: center;
                color: #999999;
                font-weight: 500;
                cursor: pointer;
                position: relative;

                img {
                    margin-right: 7px;
                    opacity: 0.3;
                }

                &.active {
                    border-color: #D22F26;
                    color: #0D0D0D;

                    img {
                        opacity: 1;
                    }

                    &::after {
                        content: " ";
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 12px 12px 12px;
                        border-color: transparent transparent #ffffff transparent;
                        position: absolute;
                        top: 48px;
                        left: calc(50% - 12px);
                        filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.1));
                        -webkit-filter: drop-shadow(0px -2px 2px rgba(0, 0, 0, 0.1));
                    }
                }
            }
        }
    }

    .file-uploader-body {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        background: #ffffff;
        border-radius: 6px;
        -moz-border-radius: 6px;
        -webkit-border-radius: 6px;
        box-shadow: 0px -2px 4px 0px rgba(153, 153, 153, 0.08);
        -moz-box-shadow: 0px -2px 4px 0px rgba(153, 153, 153, 0.08);
        -webkit-box-shadow: 0px -2px 4px 0px rgba(153, 153, 153, 0.08);
        padding: 10px 10px 7px 10px;
        margin-top: 10px;

        & > * {
            display: flex;
            align-items: center;
        }
        
        // &:before {
        //     content: "";
        //     display: inline-block;
        //     width: 0;
        //     height: 0;
        //     border-style: solid;
        //     border-width: 0 10px 15px 10px;
        //     border-color: transparent transparent #ffffff transparent;
        //     position: absolute;
        //     top: -10px;
        //     right: 40px;
        // }
    }
}