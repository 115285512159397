
  

@import '../../assets/css/variables.scss';
.user {
    .user-dropdown { 
        position: relative;

        .name {
            background: #4D4D4D;
            width: 40px;
            height: 40px;
            display: inline-block;
            font-weight: 400;
            font-size: 24px;
            color: #ffffff;
            border-radius: 50%;
            text-align: center;
            line-height: 40px;
        }
        a.box {
            padding: 8px 16px;
            display: flex;
            align-items: center;

            &.active {
                background: #EFEFEF;
                border-radius: 8px;
            }

            &:after {
                content: "";
                display: inline-block;
                position: relative;
                background: url(/assets/images/down-arrow.svg) no-repeat;
                width: 20px;
                height: 20px;
                vertical-align: sub; 
                background-position: center;
                margin-left: 15px;
                background-size: 12px auto;
            }
        } 
        .dropdown {
            background: #FFFFFF;
            box-shadow: 0px 4px 24px rgba(153, 153, 153, 0.16);
            border-radius: 6px;
            width: 155px;
            position: absolute;
            right: 0px;
            opacity: 0;
            visibility: visible;
            transition: all ease-in-out 0.5s;
            top: 100%;
            
            &.show {
                opacity: 1;
                visibility: visible;
            }
            &:before {
                content: "";
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 10px 15px 10px;
                border-color: transparent transparent #ffffff transparent;
                position: absolute;
                top: -10px;
                right: 40px;
            }
            .close {
                padding: 8px 16px;
                display: inline-block;
                font-size: 16px;
                line-height: 100%;
                width: 100%;
                color: #0D0D0D;
                background: #FFFFFF;
                box-shadow: 0px 8px 24px rgba(153, 153, 153, 0.1);
                border-radius: 6px;
                position: relative;

                &::after {
                    content: "";
                    background: url(/assets/images/clear.svg) no-repeat;
                    width: 21px;
                    height: 21px;
                    display: inline-block;
                    vertical-align: middle;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            ul {
                list-style: none;
                padding:10px 0;
                margin: 0;
                position: relative; 

                li {
                    display: inline-block;
                    width: 100%;
                    a {
                        display: inline-block;
                        width: 100%;
                        display: inline-block;
                        width: 100%;
                        color: #333333;
                        padding: 5px 15px;
                        img { 
                            width: 20px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}



.buttonsNavigation {
    display: flex; /* Use flexbox for alignment */
    justify-content: flex-end; /* Align children to the right */
    margin-left: auto; /* Ensures the container itself aligns properly if needed */
  
    > span {
      display: inline-block;
      width: 36px;
      height: 36px;
      vertical-align: middle;
      text-align: center;
      border: solid 1px #444649;
      border-radius: 5px;
      background-color: #606264;
      cursor: pointer;
  
      &.active {
        background-color: #d22f26;
        border-color: #d22f26;
      }
  
      &:first-child {
        margin-right: 8px;
      }
  
      img {
        height: 18px;
        width: auto;
        margin-top: 8px;
      }
    }
  }
  
  



 
.content-body{
    .deshboard-content {
        padding: 20px 15px;
        .title {
            h2 {
                font-weight: 600;
                font-size: 32px;
                // color: #0D0D0D;
                margin-bottom: 20px;
            }
        }
    }
    &.dark{
        .title {
            h2 {
                color: $dark_heading_text;
            }
        }
        .view-duration {
            .view-block {
                h5 {
                    color: $dark_heading_text;
                }
                .radio-list {
                    .radio-box {
                        input[type='radio'] {
                            accent-color: #fff;
                        }
                        label {
                            background: $dark_icon;
                            color: $light_sidemenu_text;
                        }
                        input[type="radio"]:checked + label {
                            background-color: #D22F26;
                            color: #ffffff;
                            img {
                                filter: brightness(0) saturate(100%) invert(100%) sepia(45%) saturate(14%) hue-rotate(290deg) brightness(114%) contrast(91%);
                            }
                        }
                    }
                }
                
            }
            .duration-block {
                h5 {
                    color: $dark_heading_text;
                }
                .radio-list {
                    .radio-box {
                        label {
                            background: $dark_icon;
                            color: $light_sidemenu_text;
                        }
                        input[type="radio"]:checked + label {
                            background-color: #D22F26;
                            color: #ffffff;
                            img {
                                filter: brightness(0) saturate(100%) invert(100%) sepia(45%) saturate(14%) hue-rotate(290deg) brightness(114%) contrast(91%);
                            }
                        }
                    }
                }
            }
        }
        .channel-container {
            .channel-table {
                .channel-table-header {
                    background: $dark_card_background;
                    .table-row {
                        .table-header-col {
                            // color: $dark_heading_text;
                        }
                    }
                }
                .channel-table-body {
                    .channel-accordion {
                        .accordion-block {
                            .toggle {
                                background: $dark_card_body;
                                .table-row {
                                    .table-col {
                                        color: $dark_heading_text;
                                    }
                                }
                               
                            }
                            .inner {
                                background: $dark_accordation_background_body;
                                .table-content {
                                    .table-row {
                                        .table-col {
                                            color: $dark_heading_text;
                                        }
                                    }
                                }
                                .pagination {
                                    background: $dark_card_background;
                                    .items-per-page {
                                        label {
                                            color: $dark_heading_text;
                                        }
                                    }
                                } 
                            }
                        }
                    }
                }
            }
            .active-user-section {
                .active-user-block {
                    background: $dark_map_card_body;
                    height: '100%';
                    .top {
                        .left-side {
                            h4, p {
                                color: $dark_heading_text;
                            }
                        }
                    }
                    .top-state {
                        h6 {
                            color: $dark_heading_text;
                        }
                        ul {
                            li {
                                span {
                                    color: $dark_heading_text;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.view-duration {
    display: flex;
    flex-wrap: wrap;
    h5 {
        font-weight: 400;
        font-size: 20px;
        line-height: 100%;
        color: #0D0D0D;
        margin-bottom: 15px;
    }
}

.view-block { 
    .radio-list {
        display: flex;
        align-items: center;
        .radio-box {
            margin-right: 20px;
            input[type="radio"] {
                opacity: 0;
                position: absolute;
            }
            label {
                background: #FFFFFF;
                box-shadow: 2px 0px 24px rgba(153, 153, 153, 0.16);
                border-radius: 6px;
                padding: 3px 8px;
                color: #999999;
                font-size: 16px;
                position: relative;
                margin-left: 25px;
                cursor: pointer;
                img {
                    margin-right: 7px;
                }
                &::before {
                    content: "";
                    display: inline-block;
                    border: 2px solid #D9D9D9; 
                    width: 18px;
                    height: 18px ;
                    display: inline-block;
                    border-radius: 50%;
                    position: absolute;
                    left: -25px;
                    top: 6px;
                }
            }
            input[type="radio"]:checked + label::after {
                content: "";
                display: inline-block;
                background-color:  #D9D9D9; 
                width: 8px;
                height: 8px;
                display: inline-block;
                border-radius: 50%;
                position: absolute;
                left: -20px;
                top: 11px;
            } 
            input[type="radio"]:checked + label {
                background-color: #D22F26;
                color: #ffffff;
                img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(45%) saturate(14%) hue-rotate(290deg) brightness(114%) contrast(91%);
                }
            }
        }
    }
}

.duration-block {
    padding-left: 80px;
    .radio-list {
        display: flex;
        align-items: center;
        .radio-box {
            margin-right:15px;
            input[type="radio"] {
                opacity: 0;
                position: absolute;
            }
            label {
                background: #FFFFFF;
                border: 0.5px solid #E6E6E6;
                border-radius: 6px;
                padding: 3px 16px;
                color: #999999;
                font-size: 16px;
                position: relative;  
                cursor: pointer;
            }
             
            input[type="radio"]:checked + label {
                background-color: #D22F26;
                color: #ffffff;
                img {
                    filter: brightness(0) saturate(100%) invert(100%) sepia(45%) saturate(14%) hue-rotate(290deg) brightness(114%) contrast(91%);
                }
            }
        }
    }
}

.channel-container {
    width: 100%;
    display: flex; 
    margin-top: 40px;
    margin-bottom: 20px;

    .channel-table{
        width: 70%;
    }
    .active-user-section {
        width: 30%;
        padding-left: 20px;
    }
}

.channel-table-header {
    background: #FFFFFF;
    box-shadow: 0px 1px 12px rgba(16, 16, 16, 0.06);
    border-radius: 6px 6px 0px 0px;
    margin-bottom: 10px;
    .table-row {
        display: flex; 
        .table-header-col {
            color: #999999;
            padding: 16px 10px;
            text-align: center;
            flex-basis: 200px;
            font-size: 13px;
            @media screen and (max-width:1800px) {
                flex-basis:150px;
            }
            &.name {
                text-align: left;
                flex-grow: 3;
            }
            small {
                display: block;
                font-size: 12px;
            }
        }
    }
}

.channel-table-body {
    max-height:700px;
    overflow: auto;
         &:-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                background-color: #F5F5F5;
            }

        &::-webkit-scrollbar {
            width: 5px;
            background-color: #F5F5F5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #404040;
            border: 2px solid #555555;
        }
  
    .accordion-block {
        margin-bottom: 10px;
        .toggle {
            background: #FFFFFF;
            box-shadow: 0px 1px 12px rgba(16, 16, 16, 0.16);
            border-radius: 6px;
            color: #333333;
            display: flex;
            flex-direction: column;
            &.active {
                border-radius: 6px 0px 0px 0px;
                .table-row {
                    .table-col {
                        font-weight: 500;
                        &.name {
                            .icon {
                                transform: rotate(0deg);
                            }
                        }
                    }
                }
            }
        }
        .inner {
            // display: none;
            background-color: #ffffff;
            padding:10px 40px;
            box-shadow: 0px 10px 12px rgba(16, 16, 16, 0.16);
            border-radius: 0 0 6px 6px;
            .info {
                font-size: 12px;
                line-height: 100%;
                background: #EFEFEF;
                border-radius: 19px;
                color: #333333;
                padding: 4px 16px;
                display: inline-block;
                width: 100%;
            }
        }
        .table-row {
            display: flex;
            justify-content: space-around;
            .table-col {
                // color: #333333;
                padding: 16px 10px;
                text-align: center;
                font-size: 13px;
                line-height: 18px;

                &:not(.name) {
                    min-width: 200px;
                    max-width: 200px;
                    @media screen and (max-width:1800px) {
                        min-width: 150px;    
                        max-width: 150px;
                    }
                }
                &.name {
                    text-align: left;
                    flex-grow: 3;
                    display: flex;
                     
                    .icon {
                        min-width: 24px;
                        text-align: center;
                        transform: rotate(-90deg);
                        transition: all ease-in-out 0.5s;
                    }
                    span {
                        // flex-grow: 3;
                    }
                    .filter-block {
                        position: relative;
                        .filter-icon {
                            // opacity: 0.2;
                            &.active {
                                opacity: 1;
                            }
                        }
                        .filter-drodown {
                            width: 200px;
                            background: #FFFFFF;
                            box-shadow: 0px 4px 24px rgba(153, 153, 153, 0.16);
                            border-radius: 6px; 
                            position: absolute;
                            left: -20px;
                            transition: all ease-in-out 0.5s;
                            margin-top: 10px;
                            opacity: 0;
                            visibility: hidden;
                            display: none;
                            z-index: 2;
                            &.show {
                                visibility: visible;
                                opacity: 1;
                                display: block;
                            }
                            &:before {
                                content: "";
                                display: inline-block;
                                width: 0;
                                height: 0;
                                border-style: solid;
                                border-width: 0 15px 15px 15px;
                                border-color: transparent transparent #ffffff transparent;
                                position: absolute;
                                top: -10px;
                                left: 12px;
                            }
                            .close {
                                padding: 8px 16px;
                                display: inline-block;
                                font-size: 16px;
                                line-height: 100%;
                                width: 100%;
                                color: #0D0D0D;
                                background: #FFFFFF;
                                box-shadow: 0px 8px 24px rgba(153, 153, 153, 0.1);
                                border-radius: 6px;
                                position: relative;
                                &:after {
                                    content: "";
                                    background: url(/assets/images/clear.svg) no-repeat;
                                    width: 21px;
                                    height: 21px;
                                    display: inline-block;
                                    vertical-align: middle;
                                    position: absolute;
                                    right: 10px;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }
                            .filter-content {
                                padding: 20px;
                                .select-box {
                                    margin-bottom: 15px;
                                    width: 100%;
                                    display: inline-block;
                                    label {
                                        font-size: 14px;
                                        line-height: 100%;
                                        color: #999999;
                                        min-width: 45px;
                                        margin-right: 10px;
                                    }
                                    select {
                                        background: #FFFFFF;
                                        border: 0.5px solid #E6E6E6;
                                        border-radius: 6px;
                                        font-weight: 400;
                                        font-size: 12px;
                                        color: #333333;
                                        width: calc(100% - 60px);
                                        padding: 3px 3px;
                                    }
                                }
                                .btn-block {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    padding-top: 10px;
                                    .btn-clear { 
                                        background: #FAEAE9;
                                        box-shadow: 0px 4px 16px rgba(153, 153, 153, 0.16);
                                        border-radius: 6px;
                                        color: #D22F26;
                                        padding: 6px 16px;
                                        font-size: 16px;
                                        line-height: 100%;
                                    }
                                    .btn {
                                        padding: 6px 16px;
                                        background: #D22F26;
                                        box-shadow: 0px 4px 16px rgba(153, 153, 153, 0.16);
                                        border-radius: 6px;
                                        color: #ffffff;
                                        font-size: 16px;
                                        line-height: 100%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .table-content {
            padding: 20px 0;
            max-height: 360px;
            overflow: auto;
            &:-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar {
                width: 5px;
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #404040;
                border: 2px solid #555555;
            }
            .table-row {
                border-bottom: 0.5px solid #CCCCCC;
                .table-col {
                    padding: 12px 10px;
                    word-wrap: break-word;
                    &.name {
                        // min-width: 240px;
                        padding-left: 0px;
                    }

                    &:last-child {
                        padding-right: 0px;
                        min-width: 160px;
                        max-width: 160px;
                        padding-left: 40px;
                        @media screen and (max-width:1800px) {
                            min-width: 100px;    
                            max-width: 100px;
                        }
                    }
                }
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }
}

// MAp


svg{
    width: 400px;
    position: relative !important;
}
.filterIcon {
    opacity: 0.2;
    &.active {
        opacity: 1;
    }
}





  /* Pagination Styles */
  .pagination {
    height:41px ;
    border-radius: 4px;
    border: 1px solid #E6E6E6;
    background: #FBFBFB;
    display: flex;
    padding: 5px 20px;
    button {
        border-radius: 4px;
        background: var(--neutral-100, #FAFAFA);
        margin: 0 10px;
        cursor: pointer;
        outline: none;
        border: none;
        color: #A3A3A3;
        font-family: Roboto;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

    
  
      &.active {
        border-radius: 6px;
        border: 0.5px solid #D9D9D9;
        background: #FFF;
        color: #333;

        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
  
      &:hover {
        border-radius: 6px;
        border: 0.5px solid #D9D9D9;
        background: #FFF;
      }
    }
    
.items-per-page {
    margin-right: auto;
  }
  label{
    color: '#333333';
    font-size: 12px; 
    font-weight: '400';
  }
 
  }

  .selectboxpage{
    margin-left: 15px;
    color: #333;
    font-family: Roboto;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 50px;
    height: 30px;
    padding: 3px 3px;
    background: white;
    border-radius: 6px;
    border: 0.5px solid rgb(217, 217, 217);

  }
  .icon-black {
    color: black;
  }
  
  