.dark-theme {
    background-color: #0D0D0D!important;
    .main-content {
        // background: linear-gradient(to bottom, #1A1A1A, #0E0E1B);
        background-color: #1A1A1A;
    }
    .dashboard-header {
        background-color: #0D0D0D;
        color: #FFFFFF;
    }
    .sidebar-menu {
        background-color: #0D0D0D;
    }
    .monitor-sidebar {
        color: #ffffff;
        width: 350px;
        overflow: auto;
        /* Change scrollbar color */
        &::-webkit-scrollbar {
            width: 5px;
            height: 5px;
        }
        &::-webkit-scrollbar-track {
            background: #343434;
        }
        &::-webkit-scrollbar-thumb {
            background: #999999;
            border-radius: 2.5px; /* Add border radius to scrollbar */
        }
        h3 {
            font-size: 20px;
            font-weight: 500;
        }
        + .content-body {
            width: calc(100% - 350px);
            margin-left: 350px;
            min-height: calc(100vh - 73px);
        }
        th {
            background-color: #3c3c3c;
            height: 18px;
            padding: 6px 12px;
            font-size: 14px;
            font-weight: 500;
            &:first-child {
                text-align: center;
            }
        }
        td{
            background-color: #2c2c2c!important;
            height: 18px;
            font-size: 12px;
            padding: 5px 10px;
        }
        table {
            background-color: #0D0D0D;
            border-radius: 8px;
            overflow: hidden;
            width: 330px;
            color: #D5D5D5;
        }
        table tr:nth-child(odd) {
            background-color: #0D0D0D!important;
        }
        tbody {
            tr {
                border-bottom: solid 1px #525252!important;
            }
        }
    }
    .channel-item {
        width: 96px;
        height: 120px;
        color:#E4E4E4;

        p {
            margin-bottom: 0;
            margin-top: 5px;
            font-size: 11px;
            margin-top: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        img {
            height: 72px;
            width: auto;
            max-width: 72px;
        }
    }
    .channel-list {
        background-color: #0D0D0D;
        border-radius: 6px;
        overflow: hidden;
        padding: 12px;
        .list-header {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 15px;
            color: #fff;
        }
        ul {
            list-style: none;
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto; // Enable horizontal scrolling
            width: 100%;
            padding-bottom: 15px;
            padding-left: 0;
            /* Change scrollbar color */
            &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
            }
            &::-webkit-scrollbar-track {
                background: #343434;
            }
            &::-webkit-scrollbar-thumb {
                background: #999999;
                border-radius: 2.5px; /* Add border radius to scrollbar */
            }
            li {
                margin-right: 10px; // Add some space between the items
                background-color: #2c2c33;
                padding: 12px;
                border-radius: 8px;
                min-width: 96px;
                text-align: center;
                font-size: 14px;
                color: #fff;
                cursor: pointer;
                &.selected {
                    background-color: #4A4A4A;
                }
            }
        }
    }
    .schedule-info {
        color: #fff;
        display: flex;
        justify-content: space-between;
        align-items: center; /* Add this line */
        vertical-align: middle;
        .total-schedule {
            color: #D5D5D5;
            font-size: 20px;
            line-height: 24px;
            vertical-align: middle;
            margin-top: 25px;
            min-width: 300px;
            span {
                font-size: 24px;
                color: #fff;
            }
        }
   
        .schedule-progress {
            width: 100%;
            background-color: #282729;
            border-radius: 5px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
            height: 16px;
            margin-top: 25px;
            .progress {
                height: 8px;
                background-color: #4caf50;
                border-radius: 5px;
                background: linear-gradient(to right, #a36bfd, #6B8AEB);
                margin-top: 4px;
                margin-left: 4px;
            }
            .progress-status {
                display: flex;
                justify-content: space-between;
                margin-top: 8px;
                span {
                    color: #999999;
                    text-transform: capitalize;
                }
            }
        }
    }
    .schedule-list {
        margin-top: 50px;
        table {
            width: 100%;
            text-align: center;
            border-radius: 8px;
            overflow: hidden;
            background-color: #0D0D0D;
            thead th{
                background: #2E3033;
                color: #D6D6D6;
                padding: 8px;
                font-weight: 400;
                width: 10%;
                &:nth-child(2) {
                    width: 15%;
                }
            }
            tr {
                background-color: #2C2C33!important;
                color: #fff;
                border-bottom: solid 1px #000;
                cursor: pointer;
                &:last-child {
                    border-bottom: none;
                }
            }
            tbody {
                tr:first-child {
                    background-color: #0D0D0D!important;
                    border-bottom: solid 1px #2C2C33;
                }
            }
            td, th {
                padding: 16px 0!important;
                &:nth-child(4) {
                    width: 35%;
                    text-align: left;
                }
            }
            img {
                filter: invert(1);
                cursor: pointer;
                &:first-child {
                    margin-right: 20px;
                }
            }
            .show-duration {
                display: inline-block;
                background-color: #2d2d2d;
                width: 68px;
                height: 24px;
                font-size: 14px;
                line-height: 24px;
                vertical-align: middle;
                text-align: center;
                border: solid 0.3px #D22F26;
                border-radius: 3px;
                position: relative;
                &::before, &::after {
                    content: "";
                    display: inline-block;
                    width: 24px;
                    height: 1px;
                    background-color: #D22F26;
                    position: absolute;
                    left: -28px;
                    top: 10px;
                }
                &::after {
                    left: 69px;
                }
            }
        }
        .monitor-type, .monitor-status {
            background-color: #333;
            width: 92px;
            height: 24px;
            line-height: 24px;
            border-radius: 6px;
            display: inline-block;
        }
        .monitor-status {
            width: 106px;
            color: #0D0D0D;
            background-color: #3FB8A9;
        }
    }
}
