.dashboard-content {
  .top-content {
    .left-side {
      h3 {
        color: #999999;
        font-size: 20px;
        font-family: Roboto;
        font-weight: 500;
        line-height: 20px;
        word-wrap: "break-word";
        margin-bottom: 20px;
      }
    }
  }

  .store-front-data {
    background-color: #fff;
    padding: 25px 20px;
    border-radius: 6px;
    margin-top: 15px;
    .feature-tab-wrapper {
      width: 100%;
      height: 40.61px;
      position: relative;
      .tab-wrapper-shadow {
        width: auto;
        height: 32px;
        left: 0px;
        top: 0px;
        position: absolute;
        background: white;
        box-shadow: 0px 4px 16px rgba(145.57, 145.57, 145.57, 0.24);
        .tab-item-wrapper {
          width: auto;
          height: 32px;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 8px;
          padding-bottom: 8px;
          left: 0px;
          top: 0px;
          position: relative;
          background: #ffffff;
          border: 0.5px #c3c3c3 solid;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 8px;
          display: inline-flex;
          cursor: pointer;
          .tab-item {
            color: "#999999";
            font-size: 16;
            font-family: "Roboto";
            font-weight: 400;
            line-height: 1;
            word-wrap: "break-word";
            float: left;
            cursor: pointer;
            // background-color: #ffffff;
            // border-color: #ffffff;
            // color: #333;
          }
          &.active {
            border-color: #d22f26;
            background-color: #d22f26;
            color: #ffffff;
            &:after {
              content: " ";
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 12px 12px 12px;
              border-color: transparent transparent #d22f26 transparent;
              position: absolute;
              top: 31px;
              left: 50%;
              margin-left: -16px;
              transform: rotate(-180deg);
              background: transparent;
            }
          }
        }
      }

      // .active-tab {
      //   width: 22.96px;
      //   height: 10.61px;
      //   left: 463px;
      //   top: 40.61px;
      //   position: absolute;
      //   transform: rotate(-180deg);
      //   transform-origin: 0 0;
      //   background: #d22f26;
      //   border-radius: 1px;
      // }
    }
    .page-content {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      position: relative;
      min-height: 450px;
      .options-list-wrapper {
        width: auto;
        height: 32px;
        position: absolute;
        margin-bottom: 20px;
        left: 100px;
        background: white;
        box-shadow: 0px 4px 8px rgba(210, 47, 38, 0.08);
        border-bottom: 0.5px #c3c3c3 solid;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .options-list {
          color: #333333;
          font-size: 16px;
          font-family: "Roboto";
          font-weight: 500;
          line-height: 1;
          word-wrap: break-word;
          padding: 10px;
          cursor: pointer;
          &.active {
            border-bottom: 3px #d22f26 solid;
          }
        }
      }
      .time-slot-wrapper {
        // margin-bottom: 20px;
        // height: 40px;
        // display: flex;
        // flex-direction: row;
        // gap: 20px;
        // align-items: center;
        margin-bottom: 20px;
        height: 40px;
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;
        justify-content: flex-end;
        width: 250px;
        position: absolute;
        right: 0;
        .automated {
          width: 120px;
          height: 25px;
          background-image: url("../../assets/images/automated-slot.svg");
          background-size: 100% 100%;
          background-position: left;
          background-repeat: no-repeat;
          cursor: pointer;
          &:hover {
            // background-image: url("../../assets/images/automated-slot-hover.svg");
          }
        }
        .blank {
          width: 90px;
          height: 25px;
          background-image: url("../../assets/images/blank-slot.svg");
          background-size: 100% 100%;
          background-position: left;
          background-repeat: no-repeat;
          cursor: pointer;
          &:hover {
            // background-image: url("../../assets/images/blank-slot-hover.svg");
          }
        }
      }
      .content-wrapper {
        width: 100%;
        height: 390px;
        display: flex;
        flex-direction: row;
        position: relative;
        overflow-y: auto;
        &.w_60 {
          width: 60%;
          margin-bottom: 50px;
          position: absolute;
          // top: 50px;
        }
        &.w_70 {
          width: 70%;
          margin-bottom: 50px;
          position: absolute;
          top: 50px;
        }
        &.w_75 {
          width: 75%;
          margin-bottom: 50px;
          position: absolute;
          top: 50px;
        }
        &.w_80 {
          width: 80%;
          margin-bottom: 50px;
          position: absolute;
          top: 50px;
        }
        &.w_90 {
          width: 90%;
          margin-bottom: 50px;
          position: absolute;
          top: 50px;
        }
        &.w_100 {
          width: 100%;
          margin-bottom: 50px;
          position: absolute;
          top: 50px;
        }
      }

      .button-wrapper {
        display: flex;
        flex-direction: row;
        position: absolute;
        bottom: -15px;
        .button-container {
          min-width: 106px;
          width: auto;
          height: 44px;
          padding-left: 24px;
          padding-right: 24px;
          padding-top: 14px;
          padding-bottom: 14px;
          background: #faeae9;
          border-radius: 8px;
          overflow: hidden;
          justify-content: center;
          align-items: center;
          gap: 12px;
          display: flex;
          margin-right: 20px;
          cursor: pointer;
          pointer-events: painted;

          .button-text {
            color: #d22f26;
            font-size: 16px;
            font-family: Roboto;
            font-weight: 500;
            line-height: 1;
            word-wrap: break-word;
            padding: 12px 20px 12px 30px;
            box-sizing: border-box;
          }
          .arrow {
            color: #d22f26;
            font-size: 20px;
            font-family: Roboto;
            font-weight: 500;
            line-height: 1;
            word-wrap: break-word;
            margin-left: 10px;
            transform: rotate(90deg);
            display: inline-block;
          }
          &:hover {
            background: #d22f26;
            .button-text {
              color: #ffffff;
            }
            .arrow {
              color: #ffffff;
            }
          }
          &.disabled {
            cursor: none;
            pointer-events: none;
            background: #d3d3d3;
            .button-text {
              color: #ffffff;
            }
            .arrow {
              color: #ffffff;
            }
            &:hover {
              background: #d3d3d3;
              .button-text {
                color: #ffffff;
              }
            }
          }
        }
      }

      .store-front-table {
        width: 100%;
        position: absolute;
        .table-action-group {
          display: flex;
          align-items: center;
          gap: 5px;
        }
        thead {
          max-height: 50px;
        }
        tbody {
          min-height: 100px;
          max-height: 300px;
          overflow: auto;
          width: 100%;
        }
        .no-content {
          display: block !important;
          position: absolute !important;
        }
        tr {
          cursor: pointer;
          max-height: 50px;
          border-bottom: 1px solid #efe2e2;
          .dragabble {
            background-color: #a9acae;
          }
        }
        th {
          text-transform: uppercase;
          background: #efe2e2;
          padding: 14px 15px;
          font-size: 14px;
          color: #927f7f;
          font-weight: 500;
          line-height: 20px;
        }
        th,
        td {
          font-weight: 500;
          line-height: 20px;
          padding: 14px 15px;
          color: #333;
          &:first-child {
            width: 10%;
          }
          &:nth-child(2) {
            width: 20%;
          }
          &:nth-child(3) {
            width: 35%;
          }
          &:nth-child(4) {
            width: 23%;
          }
          &:nth-child(5) {
            width: 12%;
          }
          .dragabble-icon {
            cursor: pointer;
            width: 12px;
            height: 20px;
          }
          // .dragableIcon {
          //   background-image: url("../../assets/images/dragable-icon.png");
          //   background-position: center;
          //   background-repeat: no-repeat;
          //   background-size: 50%;
          // }
          &.w-10 {
            width: 10%;
          }
          &.w-15 {
            width: 15%;
          }
          &.w-20 {
            width: 20%;
          }
          &.w-25 {
            width: 25%;
          }
          &.w-30 {
            width: 30%;
          }
          &.w-40 {
            width: 40%;
          }
          &.w-50 {
            width: 50%;
          }
        }

        .icon-group {
          position: relative;
          display: flex;
          flex-direction: row;
          gap: 20px;

          .table-icon {
            width: 33%;
            display: inline-block;
            cursor: pointer;
          }
          .table-text {
            width: 33%;
            display: inline-block;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &.small {
            gap: 10px;
            .table-icon {
              width: 40px;
            }
          }
          &.feature {
            gap: 15px;
          }
        }
        .img-icon {
          border-radius: 12px;
          background: #efebeb;
          padding: 4px 8px;
          margin-right: 12px;
          &:last-of-type {
            margin-right: 0;
          }
        }
        &.tbl-50-per {
          width: 50%;
          border: 1px solid lightgray;
          th,
          td {
            text-align: center;
            font-weight: normal;
            &:first-child {
              width: 10%;
            }
            &:nth-child(2) {
              width: 40% !important;
            }
            &:nth-child(3) {
              width: 40% !important;
            }
            &:nth-child(4) {
              width: 10%;
            }
          }
        }
        .date-range {
          width: 70%;
          height: 30px;
          background: #f9f9f9;
          border: 1px solid #e6e6e6;
          border-radius: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          &.w_80 {
            width: 80%;
          }
          &.w_100 {
            width: 100%;
          }
          .from-date {
            font-size: 14px;
            color: #000000;
            margin-left: 5px;
            margin-right: 10px;
            cursor: pointer;
            &.empty {
              font-size: 12px !important;
            }
          }
          .to {
            font-size: 12px;
            margin-right: 10px;
            color: #808080;
          }
          .to-date {
            font-size: 14px;
            color: #000000;
            margin-right: 10px;
            cursor: pointer;
            &.empty {
              font-size: 12px !important;
            }
          }
          .calander-icon {
            position: relative;
            right: 3px;
            height: 16px;
            width: 16px;
            background-image: url("../../assets/images/date_range.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: block !important;
          }
        }
      }

      .channel-listing-cseq {
        width: 30%;
        height: 420px;
        position: absolute;
        overflow-y: auto;
        left: 70%;
        background: #efe2e2;
        &.top {
          top: 50px;
        }
        .search-wrapper {
          left: 5%;
          top: 24px;
          position: absolute;
          background: #f4f2f2;
          border-radius: 6px;
          border: 1px #e6e6e6 solid;
          justify-content: flex-end;
          align-items: center;
          gap: 52px;
          display: inline-flex;
          width: 90%;
          height: 60px;
          .text-wrapper {
            color: #999999;
            font-size: 14px;
            font-family: Roboto;
            font-weight: 400;
            line-height: 1;
            word-wrap: break-word;
            width: 70%;
            input {
              color: #999999;
              font-size: 14px;
              font-family: Roboto;
              font-weight: 400;
              padding: 5px;
              border: 0;
              background: #f4f2f2;
              height: 30px;
              margin-left: 10px;
              width: 100%;
              &:focus {
                outline: none;
              }
            }
          }
          .search-icon {
            width: 24px;
            height: 24px;
            position: relative;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            display: flex;
            right: 14px;
            top: 5px;
          }
        }

        .list-wrapper {
          left: 5%;
          width: 90%;
          top: 100px;
          position: relative;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 8px;
          display: inline-flex;
          padding-bottom: 20px;
          box-sizing: border-box;
          .list-item {
            padding: 8px;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            display: flex;
            background: transparent;
            border-radius: 5px;
            width: 100%;
            cursor: pointer;
            min-height: 40px;
            &:hover {
              background: #ffffff;
            }
            .channel-icon {
              width: 40px;
              height: 40px;
              position: relative;
            }
            .channel-name {
              width: 186px;
              color: rgb(51, 51, 51);
              font-size: 16px;
              font-family: Roboto;
              font-weight: 400;
              line-height: 1;
              overflow-wrap: break-word;
            }
            .channel-arrow {
              transform: rotate(-90deg);
              width: 20px;
              height: 20px;
              background-image: url("../../assets/images/expand_more.svg");
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              position: absolute;
              right: 10px;
            }
          }
          .item-wrapper {
            display: block;
            height: auto;
            width: 100%;
            .nexted-list {
              list-style: none;
              display: flex;
              flex-direction: column;
              display: none;
              margin: 0;
              padding: 0;
              .nexted-list-item {
                padding: 5px;
                margin-bottom: 5px;
                text-align: left;
                cursor: pointer;
                .program-icon {
                  width: 30px;
                  height: 30px;
                  position: relative;
                  margin-right: 10px;
                }
                .program-name {
                  width: 186px;
                  color: rgb(51, 51, 51);
                  font-size: 16px;
                  font-family: Roboto;
                  font-weight: 400;
                  line-height: 1;
                  overflow-wrap: break-word;
                }
                &:hover {
                  background: #ffffff;
                }
              }
            }

            &.active {
              .list-item {
                background: #d22e26;
                .channel-name {
                  color: #ffffff;
                }
                .channel-arrow {
                  transform: rotate(0deg);
                  width: 20px;
                  height: 20px;
                  background-image: url("../../assets/images/expand_more_white.svg");
                  background-position: center;
                  background-size: contain;
                  background-repeat: no-repeat;
                  position: absolute;
                  right: 10px;
                }
              }
              .nexted-list {
                display: flex;
                margin-top: 10px;
              }
            }
          }
        }
      }

      /*---- checkbox design start -----*/
      .checkbox-container {
        display: block;
        position: relative;
        padding-left: 10px;
        box-sizing: border-box;
        // padding-left: 35px;
        // margin-bottom: 12px;
        cursor: pointer;
        font-size: 14px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        flex-direction: row;
        align-self: center;
        justify-content: center;
      }

      /* Hide the browser's default radio button */
      .checkbox-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom radio button */
      .checkbox-container .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #ffe2e1;
        border: 1px solid #d22e26;
        border-radius: 50%;
      }

      /* On mouse-over, add a grey background color */
      .checkbox-container:hover input ~ .checkmark {
        background-color: #ffe2e1;
      }

      /* When the radio button is checked, add a blue background */
      .checkbox-container input:checked ~ .checkmark {
        background-color: #4fc17e;
        border: 0px;
      }

      /* Create the indicator (the dot/circle - hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the indicator (dot/circle) when checked */
      .checkbox-container input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the indicator (dot/circle) */
      .checkbox-container .checkmark:after {
        top: 2px;
        left: 2px;
        width: 16px;
        height: 16px;
        border: 3px solid #ffffff;
        border-radius: 50%;
        background: #4fc17e;
      }
      /*---- checkbox design end -----*/
    }
  }
}

// ::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
//   border-radius: 4px;
//   background-color: #f5f5f5;
// }

// ::-webkit-scrollbar {
//   width: 4px;
//   background-color: #f5f5f5;
// }

// ::-webkit-scrollbar-thumb {
//   border-radius: 4px;
//   -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
//   background-color: #d62929;
//   width: 2px;
// }
