.playlist-folder {
    font-family: Arial, sans-serif;
    display: flex;
    align-items: center;
    justify-content: space-between; 
 
    padding: 10px;
  
    .dropdown-wrapper {
      position: relative;
      width: 270px; 
  
      .dropdown-header {
        background-color: #121212;
        border: 1px solid #D22F26;
        border-radius: 4px;
        padding: 8px 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        user-select: none;
        
  
        .dropdown-title {
          font-weight: 400;
          color: #FFFFFF;
          font-family: 'Roboto';
        }
  
        .dropdown-arrow {
          font-size: 14px;
          color: #666;
        }
  
        &:hover {
          background-color: #99999929;
        }
      }
  
      .dropdown-menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        margin-top: 5px;
  
        .dropdown-option {
          display: flex;
          align-items: center;
          padding: 6px 12px;
          font-size: 14px;
          cursor: pointer;
  
          &:hover {
            background-color: #f7f7f7;
          }
  
          input[type="checkbox"] {
            margin-right: 8px;
            cursor: pointer;
          }
        }
      }
    }
  
    .add-folder-icon {
      background-color: #444649;
      padding: 5px;
      border-radius: 6px;
  
      img {
        width: 32px;
        height: 32px;
        cursor: pointer;
  
        &:hover {
          opacity: 0.8;
        }
      }
    }
   
  }
  .heading-popup{
    color: #FFFFFFCC;
    font-size: 16px;
    padding-left: 10px;
    font-weight: 400;
    font-family: 'Roboto';
}
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); // Semi-transparent background
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-container {
    background: #1A1A1A; 
    border: 1px solid #ffffff52;
    border-radius: 16px;
    width: 576px; // Fixed width
    height: 616px; // Fixed height
    padding: 64px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    h3 {
      margin-bottom: 20px;
      font-size: 24px;
      color: #ffffff; // White text color
    }
  
    .popup-form {
      text-align: left;
      flex-grow: 1;
  
      label {
        display: block;
        margin-bottom: 15px;
        color: #BFBFBF; 
        font-size: 14px;
  
        input,
        textarea {
          width: 100%;
          margin-top: 5px;
          padding: 15px;
          border: 1px solid #444648;
          border-radius: 4px;
          background: #FFFFFF14;
          color: #ffffff; // White text inside inputs
          font-size: 14px;
        }
  
        textarea {
          resize: none;
          height: 50px; // Reduced height
        }

   
      }
    }
  
    .popup-actions {
      display: flex;
      gap: 20px;
      margin-top: 15px;
  
      button {
        padding: 12px 25px;
        font-size: 14px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
  
        &.cancel-btn {
          background: #FAEAE9;
          color: #D22F26;
          font-size: 16px;
          font-weight: 500;
        }
  
        &.create-btn {
          background: #D22F26;
          color: #ffffff;
          font-size: 16px;
          font-weight: 500;
       
        }
      }
    }
  }
  .error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
    display: block; // Ensures the message appears below the input
  }
  
  .required-asterisk {
    color: red;
    margin-left: 4px; // Adds some space between the label and asterisk
    font-size: 16px;
    font-weight: bold;
  }
  
  .content-partner-field {
    position: relative;
    .dropdown-input {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      background: white;
      .placeholder {
        color: #aaa;
      }
      .selected {
        color: #333;
      }
      .dropdown-arrow {
        width: 16px;
        height: 16px;
      }
    }
    .dropdown-list {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 10;
      background: white;
      border: 1px solid #ccc;
      border-radius: 4px;
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
      max-height: 150px;
      overflow-y: auto;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      .dropdown-item {
        padding: 10px;
        cursor: pointer;
        &:hover {
          background: #f0f0f0;
        }
        &.selected {
          background: #e0e0e0;
          font-weight: bold;
        }
      }
    }
  }
  .dropdown-wrapper{
    position: relative;
  
    .dropdown-header {
        width: 100%;
        margin-top: 5px;
        padding: 15px;
        border: 1px solid #444648;
        border-radius: 4px;
        background: #FFFFFF14;
        color: #ffffff;
        font-size: 14px;
        justify-content: space-between;
        align-items: center;
        display: flex;
        cursor: pointer;
  
      &.open {
        border-color: #888;
      }
  
      .dropdown-title {
        color: #999; // Default placeholder color
        font-weight: 400;
  
        // Change color when an option is selected
        &:not(:empty) {
          color: #99999999;
        }
      }
  
      .dropdown-arrow img {
        width: 12px;
        height: 12px;
      }
    }
  
    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      margin-top: 4px;
      z-index: 1000;
  
      .dropdown-option {
        padding: 8px 12px;
        cursor: pointer;
  
        &:hover {
          background-color: #f1f1f1;
        }
      }
    }
  }
  
  