.active-user-section {
    padding-left: 20px;
    .active-user-block {
        width: 100%;
        background: #2E3033;
        box-shadow: 0px 1px 12px rgba(16, 16, 16, 0.06);
        border-radius: 6px;
        padding: 20px;
        height: '100%';
        color: #fff;
        .top {
            display: flex;
            justify-content:space-between;
            .left-side {
                h4 {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    color: #fff;
                    margin: 0;
                }
                p {
                    color: #fff;
                    font-size: 16px;
                    line-height: 100%;
                }
            }
            // .zoom-map {
            //     .pulse {
            //         margin-right: 8px;
            //         display: flex;
            //     }
            // }
            .zoom-map {
                display: flex;
                justify-content: space-between; /* Ensures buttons are spaced apart */
                align-items: center; /* Aligns items vertically in the center */
              }
              
              .pulse {
            //   color: blue; 
                padding: 1px;
                border-radius: 50%;
                cursor: pointer;
              }
            //   .zoomIn{
            //     background-color: red;
            //   }
              
              .minus {
                // background-color: red;
                padding: 10px;
                border-radius: 50%;
                cursor: pointer;
              }
              
              /* You can add more specific styling for hover effects or other states if needed */
              .pulse:hover, .minus:hover {
                opacity: 0.8; /* Adds a simple hover effect */
              }
              
           
        }
        .map-block {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
            }
        }
    }
}

.top-state {
    margin-top: 50px;
    width: 100%;
    height: 100%;
    h6 {
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        text-align: center;
        margin-bottom: 20px; 
    }
    ul {
        list-style: none;
        padding: 0;
        // display: flex;
        // flex-wrap: wrap;
        li { 
            width: 100%;
            padding: 0 30px;
            display: flex;
            justify-content: space-between;
        }
    }
}
// .active-user-section {
//     padding-left: 20px;
  
//     .active-user-block {
//       width: 100%;
//       background: #FFFFFF;
//       box-shadow: 0px 1px 12px rgba(16, 16, 16, 0.06);
//       border-radius: 6px;
//       padding: 20px;
  
//       .top {
//         display: flex;
//         justify-content: space-between;
  
//         .left-side {
//           h4 {
//             font-weight: 500;
//             font-size: 20px;
//             line-height: 24px;
//             color: #0D0D0D;
//             margin: 0;
//           }
  
//           p {
//             color: #333333;
//             font-size: 16px;
//             line-height: 100%;
//           }
//         }
  
//         .zoom-map {
//           .pulse {
//             margin-right: 8px;
//           }
//         }
//       }
  
//       .map-block {
//         width: 100%;
//         img {
//           width: 100%;
//         }
//       }
//     }
//   }

//   .state-header-text {
//     text-align: center; 
//     padding: 10px 0; 
//     font-size: 18px; 
//     font-weight: 600;
//     color: #0D0D0D;
//     margin-top: 50px;

//     @media (prefers-color-scheme: dark) {
//         color: #ffffff; 
//       }
//   }
  
//   .state-grid {
//     margin-top: 30px;
//     display: grid;
//     grid-template-columns: repeat(2, 1fr); // 2 columns
//     grid-template-rows: repeat(3, auto); // 3 rows
//     // gap: 50px;
  
//     .grid-item {
//       display: flex;
//       justify-content: space-between;
//       padding-left: 20px;
//     //   background-color: #f7f7f7;
//     //   border-radius: 6px;
//     //   box-shadow: 0px 1px 5px rgba(16, 16, 16, 0.1);
  
//       span {
//         font-size: 14px;
//         color: #333;
//       }
//     }
//   }
  
  