/*=====dark colors start====*/

$dark_button_background: #d22f26;
$dark_navbar_background: #0D0D0D;
$dark_background: #1A1A1A;
$dark_popup: #2e3033;
$dark_borders: #606264;
$dark_tab_header: #444649;
$dark_icon: #e9e9e9;
$dark_heading_text: #ffffff;
$dark_subheading: #999999;
$dark_subtext: #d9d9d9;

// #E9E9E9


// 

$dark_card_background: #444649;
$dark_card_body: #0D0D0D;

$dark_accordation_background_body: #1A1A1A;


// map card background
// $dark_map_card_body: #4C4C4E;
$dark_map_card_body: #444649;


$dark_sidemenu_text: #ffffff;
$dark_sidemenu_active_text: #ffffff;
$dark_sidemenu_active_background: #d22f26;

/*=====dark colors end====*/

/*=====light colors start====*/

$light_button_background: #d22f26;
$light_navbar_background: #ffffff;
$light_background: #f9f9f9;
$light_popup: #ffffff;
$light_borders: #e9e9e9;
$light_tab_header: #444649;
$light_icon: #ffffff;
$light_heading_text: #000000;
$light_subheading: #999999;
$light_subtext: #646464;

$light_card_background: #f7f7f7;
$light_card_body: #ffffff;

$light_sidemenu_text: #333333;
$light_sidemenu_active_text: #ffffff;
$light_sidemenu_active_background: #d22f26;
/*=====light colors end====*/

$universal_white: #ffffff;
$universal_black: #000000;
$border_box: #99999914;
