  
  .video-container {
    display: flex;
    // justify-content: space-between;
    gap: 16px;
  }
  
  @media (max-width: 991px) {
    .video-container {
      justify-content: center;
      margin: 0 4px;
    }
  }
  
  .video-title {
    color: #333;
    font: 700 20px/140% Roboto, -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .image-container {
    border-radius: 6px;
    border: 0.5px solid #444649;
    background-color: #444649;
    width: 36px;
    max-width: 100%;
    flex-direction: column;
    padding: 6px;
height: 36px;
  }
  
  .image {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 100%;
    overflow: hidden;
  }
  
  .video-details {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .detail-row {
    display: flex;
    flex-direction: column;
    // gap: 8px;
    margin-bottom: 13px;
  }
  
  .detail-label {
    font-weight: bold;
  }
  
  .detail-input {
    margin-right: 20px;
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 6px;
background: #F9F9F9;

border: 1px solid #E6E6E6;
  }
  
  .detail-label:last-child {
    margin-top: 8px;
  }
  .upload-section{
    justify-content: space-between;
    margin:22px 0px;
  }
  .folder-icon {
    height: 110px;
    position: relative;
    border-radius: 8px;
    border: 0.5px dashed #D22F26;
 padding: 5px;
//  background-color: #FFFFFF14;
 @media (max-width: 1400px) {
  height: 80px;
  

}



  

  .background {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center */
    /* Add any other styles you need */
  

    width: 100%;
    height: 100%;
    
   
    background: #FFFFFF14               ;
    border-radius: 8px;
  
  }
  
 
 
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center; /* Center vertically */
      align-items: center; /* Center horizontally */
      /* Add any other styles you need */

    
    img{
      width: 24px;
      height: 24px;
      flex-shrink: 0;
    }
  }
  
  // .icon {
  //   width: 24px;
  //   height: 24px;
  //   left: 34px;
  //   top: 0px;
  //   position: absolute;
  //   display: inline-flex;
  // }
  
  // .label {
  //   width: 92px;
  //   height: 24px;
  //   left: 0px;
  //   top: 32px;
  //   position: absolute;
  //   display: inline-flex;
  //   flex-direction: column;
  //   justify-content: flex-start;
  //   align-items: center;
  //   gap: 3px;
  // }
  
  .title {
    color: #D9D9D9;
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
 
}
/* CategoryItem.css */
.label-box {
  width: 25%;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #0D0D0D;
  border-radius: 6px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  /* Gradient border */
  border: none;
  background: linear-gradient(#0D0D0D, #0D0D0D) padding-box, 
              linear-gradient(45deg, #FFFFFF29,  #FFFFFF,#FFFFFF29,) border-box;
  border: 0.5px solid transparent;


    


.icon-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: inline-flex;
}

.folder-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;

    
}

.folder-icon {
    width: auto;
    height: 19.87px;
}

.text {
    color: #FFF;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 700;
    letter-spacing: 0.10;
    word-wrap: break-word;
}
}
.blank-text{
    font-family: Roboto;
font-size: 20px;
font-weight: 700;
line-height: 28px;
letter-spacing: 0px;
text-align: center;
width: 232px;
height: 56px;
color: #755E87;
}
.delete-icon{
  position: absolute;
  z-index: 1;
  cursor: pointer;
  right: 8px;
  top: 8px;
  background: #444649;
  width: 15px;
  height: 22px;

}


.right-content{
    padding: 20px;
    // width: 442px;
    min-height: 74.5vh;
flex-shrink: 0;
border-radius: 8px;
border: 1px solid #E6E6E6;
background: #FFF;
box-shadow: 0px 8px 24px 0px rgba(16, 24, 40, 0.08);
overflow-y: auto; /* Enable vertical scrolling */
overflow-x: hidden;

// @media screen and (max-width:1800px) {
    // width:auto;
    
// }

}

.dashboard-content {
  .right-content{
    padding: 20px 0;
    > * {
      padding: 0 20px;
    }
    .part-height {
      + div {
        box-shadow: 0px -4px 10px 0px rgba(169, 169, 169, 0.15);
        padding-top: 14px;
      }
    }
  }
}

.detail-input{

  
    padding: 12px, 16px, 12px, 16px;
    border-radius: 6px;
    gap: 8px;
    
}
textarea{
    height: 200px ;
}
.back-image{
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

